import React, { useEffect } from 'react';
import { ArrowLeft, Zap, Brain, Layers, Cpu, Globe, Users, Settings, ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

function ProductDetails() {
  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fadeIn = {
    hidden: { opacity: 0, y: 5 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 0.4, ease: "easeOut" } 
    },
  };

  const technologies = [
    {
      icon: <Zap size={32} className="text-[#E67E22]" />,
      title: "Patented Dual-Piston Force Feedback System",
      description: "Our cutting-edge dual-piston technology precisely simulates a wide spectrum of physical interactions and terrains. Walk on sandy beaches, climb rugged mountains, or handle virtual tools—all with authentic resistance and tactile feedback."
    },
    {
      icon: <Layers size={32} className="text-[#E67E22]" />,
      title: "Full-Body Haptic Feedback and Natural Movement",
      description: "ExoSuit VR envelops you in a comprehensive sensory experience. The exoskeleton provides realistic force feedback across your entire body, allowing you to move naturally and interact physically with virtual objects."
    },
    {
      icon: <Brain size={32} className="text-[#E67E22]" />,
      title: "Adaptive AI Integration",
      description: "Our sophisticated AI algorithms dynamically adjust to your movements and preferences in real-time. This ensures that each interaction is intuitively responsive, providing a personalized experience that enhances the realism and effectiveness of your virtual engagements."
    },
    {
      icon: <Cpu size={32} className="text-[#E67E22]" />,
      title: "High-Performance Computing",
      description: "Powered by state-of-the-art processors, ExoSuit VR handles complex simulations effortlessly. Enjoy smooth, responsive interactions with minimal latency, even in the most demanding virtual scenarios where precise physical interaction is crucial."
    }
  ];

  const capabilities = [
    {
      icon: <Globe size={24} className="text-[#E67E22]" />,
      title: "Unparalleled Realism",
      description: "ExoSuit VR's high-fidelity haptic feedback system simulates an extensive array of physical sensations. Feel the weight of virtual objects, the resistance of different materials, and the nuances of various textures, creating an unmatched level of immersion that transcends traditional VR experiences."
    },
    {
      icon: <Users size={24} className="text-[#E67E22]" />,
      title: "Multi-User Physical Interaction",
      description: "Our system supports seamless multi-user experiences, enabling collaborative training scenarios and shared virtual spaces where participants can physically interact with each other and the environment. This feature opens up new possibilities for teamwork, communication, and social interaction in virtual settings."
    },
    {
      icon: <Settings size={24} className="text-[#E67E22]" />,
      title: "Modular and Scalable Design",
      description: "ExoSuit VR is designed with a modular architecture, allowing for easy customization and upgrades. Tailor your exoskeleton to specific industry needs or applications, ensuring that the system evolves with technological advancements and adapts to your requirements."
    }
  ];

  return (
    <div className="container mx-auto px-6 py-24 max-w-7xl">
      <Link to="/product" className="flex items-center text-[#E67E22] mb-8 hover:underline transition-colors duration-300">
        <ArrowLeft className="mr-2" size={20} />
        Back to Product Overview
      </Link>
      
      <motion.h1 
        className="text-5xl font-chillax-bold mb-6 text-[#FFFFFF] leading-tight"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        ExoSuit VR: The Pinnacle of Physical Immersion in Virtual Reality
      </motion.h1>
      <motion.p 
        className="text-xl font-avenir text-[#f2f2f2] mb-16 max-w-3xl"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Discover the revolutionary features that make ExoSuit VR the future of immersive technology. Experience a world where you can move, feel, and interact with digital environments as if they were tangible, thanks to our advanced exoskeleton and force feedback systems.
      </motion.p>

      <section className="mb-24">
        <h2 className="text-3xl font-chillax-bold text-[#FFFFFF] mb-8">Breakthrough Technologies</h2>
        <div className="grid md:grid-cols-2 gap-12">
          {technologies.map((tech, index) => (
            <motion.div 
              key={index}
              className="bg-gradient-to-br from-[#2a3540] to-[#1d2027] p-8 rounded-2xl shadow-lg"
              variants={fadeIn}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, margin: "-50px" }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="flex items-center mb-4">
                {tech.icon}
                <h3 className="text-2xl font-chillax-bold ml-4 text-[#FFFFFF]">{tech.title}</h3>
              </div>
              <p className="text-md text-[#f2f2f2] font-avenir leading-relaxed">{tech.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      <section className="mb-24">
        <h2 className="text-3xl font-chillax-bold text-[#FFFFFF] mb-8">Advanced Capabilities</h2>
        <motion.div 
          className="bg-gradient-to-br from-[#2a3540] to-[#1d2027] p-8 rounded-2xl shadow-lg"
          variants={fadeIn}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-50px" }}
        >
          <ul className="space-y-6 text-[#f2f2f2] font-avenir">
            {capabilities.map((capability, index) => (
              <motion.li 
                key={index} 
                className="flex items-start"
                variants={fadeIn}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, margin: "-50px" }}
                transition={{ delay: index * 0.1 }}
              >
                {capability.icon}
                <div className="ml-4">
                  <h4 className="text-xl font-semibold mb-2">{capability.title}</h4>
                  <p>{capability.description}</p>
                </div>
              </motion.li>
            ))}
          </ul>
        </motion.div>
      </section>

      <section className="mb-24">
        <h2 className="text-3xl font-chillax-bold text-[#FFFFFF] mb-8">Technical Insights</h2>
        <motion.div 
          className="bg-gradient-to-br from-[#2a3540] to-[#1d2027] p-8 rounded-2xl shadow-lg"
          variants={fadeIn}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-50px" }}
        >
          <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed mb-6">
            ExoSuit VR pushes the boundaries of what's possible in virtual reality by emphasizing physical interaction and force feedback:
          </p>
          <ul className="space-y-4 text-[#f2f2f2] font-avenir">
            {[
              { title: "Precise Force Simulation", description: "Our dual-piston system delivers a broad range of force feedback, accurately replicating physical interactions from gentle touches to substantial resistance, enabling realistic handling of virtual objects and environments." },
              { title: "Low-Latency Performance", description: "With high-speed connectivity, ExoSuit VR ensures immediate response to your movements, so that physical interactions feel seamless and natural." },
              { title: "Advanced Motion Tracking", description: "Integrated sensors provide highly accurate full-body tracking, translating your real-world movements into the virtual environment with precision. This allows you to move freely and interact physically with virtual objects and surroundings." },
              { title: "Ergonomic Design", description: "Engineered for comfort during extended use, the exoskeleton features adjustable components to accommodate a wide range of body types. This design promotes natural movement and reduces fatigue, enhancing the overall experience." }
            ].map((insight, index) => (
              <li key={index}>
                <span className="font-semibold">{insight.title}:</span> {insight.description}
              </li>
            ))}
          </ul>
          <p className="mt-6 text-[#E67E22] font-avenir italic">
            For detailed technical specifications and performance metrics, please contact our engineering team.
          </p>
        </motion.div>
      </section>

      <section>
        <h2 className="text-3xl font-chillax-bold text-[#FFFFFF] mb-8">Customization and Integration</h2>
        <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed mb-8">
          We understand that each industry and application has unique requirements. Our team works closely with clients to develop tailored ExoSuit VR solutions that align perfectly with specific needs:
        </p>
        <ul className="list-disc list-inside space-y-4 text-[#f2f2f2] font-avenir mb-8">
          <li>Customized Haptic Profiles: Develop specific force feedback settings for industry-specific simulations, ensuring that physical interactions are relevant and effective for your use case.</li>
          <li>Integration with Existing Systems: Seamlessly incorporate ExoSuit VR with your current training systems, software platforms, and VR content to enhance physical interaction capabilities without disrupting your workflow.</li>
          <li>Bespoke Virtual Environments: Create custom virtual worlds and scenarios where users can move and interact physically, tailored to your training objectives, entertainment experiences, or collaborative projects.</li>
          <li>Scalable Solutions: Whether you need a single unit or a large-scale deployment, ExoSuit VR can be scaled to meet your requirements and budget, making it accessible for organizations of all sizes.</li>
        </ul>
        <motion.button
          className="bg-[#E67E22] text-white px-6 py-3 rounded-full hover:bg-[#D35400] transition duration-300 flex items-center text-lg font-semibold"
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.99 }}
        >
          Explore Custom VR Solutions <ArrowRight className="ml-2" size={20} />
        </motion.button>
      </section>
    </div>
  );
}

export default ProductDetails;