import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Phone, Menu, X } from 'lucide-react';

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 0);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isActive = (path) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname.startsWith(path);
  };

  const NavLink = ({ to, children }) => (
    <Link 
      to={to} 
      className={`text-[#f2f2f2] hover:text-[#E67E22] transition-all duration-200 text-base font-semibold ${
        isActive(to) 
          ? 'text-[#E67E22] border-b-2 border-[#E67E22] pb-1' 
          : ''
      }`}
      onClick={() => setIsMobileMenuOpen(false)}
    >
      {children}
    </Link>
  );

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className={`bg-gradient-to-r from-[#1d2027] to-[#2a3540] py-4 px-6 flex justify-between items-center transition-all duration-300 ${isScrolled ? 'fixed top-0 left-0 right-0 shadow-md z-50' : ''}`}>
      <Link to="/" className="text-3xl font-chillax-bold text-[#FFFFFF] tracking-wide hover:text-[#E67E22] transition-colors duration-200">newton</Link>
      <nav className="hidden md:flex space-x-6 font-chillax-bold">
        <NavLink to="/">Home</NavLink>
        <NavLink to="/product">Product</NavLink>
        <NavLink to="/team">Team</NavLink>
        <NavLink to="/vision">Vision</NavLink>
        <NavLink to="/exoskeleton-applications">Applications</NavLink>
        <NavLink to="/contact-us">Contact</NavLink>
      </nav>
      <div className="hidden md:flex items-center space-x-4 font-avenir">
        <Phone size={18} className="text-[#FFFFFF]" />
        <span className="text-[#f2f2f2] text-sm">+972 559572906</span>
      </div>
      <button 
        aria-label="Toggle mobile menu"
        className="md:hidden text-[#f2f2f2] hover:text-[#E67E22] transition-colors duration-200" 
        onClick={toggleMobileMenu}
      >
        {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      {/* Mobile Menu */}
      <div className={`fixed inset-y-0 right-0 w-64 bg-gradient-to-b from-[#1d2027] to-[#2a3540] z-50 transform ${isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'} transition-all duration-300 ease-in-out md:hidden`}>
        <div className="flex flex-col p-6 space-y-4 font-chillax-bold">
          <button
            aria-label="Close mobile menu"
            className="self-end text-[#f2f2f2] hover:text-[#E67E22] transition-colors duration-200 mb-4"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <X size={24} />
          </button>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/product">Product</NavLink>
          <NavLink to="/team">Team</NavLink>
          <NavLink to="/vision">Vision</NavLink>
          <NavLink to="/exoskeleton-applications">Applications</NavLink>
          <NavLink to="/contact-us">Contact</NavLink>
          <div className="flex items-center space-x-2 mt-4 font-avenir">
            <Phone size={18} className="text-[#FFFFFF]" />
            <span className="text-[#f2f2f2] text-sm">+972 559572906</span>
          </div>
        </div>
      </div>
    </header>
  );
}

export default React.memo(Header);