import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, Zap, Hand, Brain, Shield, BarChart, Cog } from 'lucide-react';
import { motion } from 'framer-motion';

function Home() {
  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fadeIn = {
    hidden: { opacity: 0, y: 10 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 0.5, ease: "easeOut" } 
    },
  };

  return (
    <>
  {/* Hero Section */}
  <section className="relative h-screen overflow-hidden">
    <div className="absolute inset-0">
      <picture>
        <source
          media="(min-width: 768px)"
          srcSet="/images/heroImage.jpg"
        />
        <source
          media="(max-width: 767px)"
          srcSet="/images/hero image 1x1.jpg"
        />
        <img
          src="/images/hero image 1x1.jpg"
          alt="Newton VR Exoskeleton"
          className="w-full h-full object-cover md:object-contain lg:object-cover object-center"
          style={{ objectPosition: '50% 50%' }}
        />
      </picture>
        </div>
        <motion.div 
          className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <ChevronDown className="text-white animate-bounce" size={40} />
        </motion.div>
      </section>

      {/* Main Introduction Section */}
      <section className="bg-gradient-to-r from-[#1d2027] to-[#2a3540] py-24">
        <div className="container mx-auto px-4 md:px-6 relative">
          <motion.div 
            className="text-center max-w-4xl mx-auto"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            <h1 className="text-5xl md:text-7xl font-chillax-bold text-white mb-8 leading-tight">
              Redefining Virtual Reality
            </h1>
            <p className="text-xl md:text-2xl text-[#f2f2f2] font-avenir mb-12 leading-relaxed">
              Experience unparalleled precision and realism with Newton's groundbreaking full-body exoskeleton technology.
            </p>
            <Link to="/product">
              <motion.button 
                className="bg-[#E67E22] hover:bg-[#D35400] text-white text-lg font-chillax-bold py-4 px-8 rounded-full transition duration-300"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Explore Our Technology
              </motion.button>
            </Link>
          </motion.div>
        </div>
      </section>

      {/* Key Innovations Section */}
      <section className="bg-[#1d2027] py-24">
        <div className="container mx-auto px-4 md:px-6">
          <motion.h2 
            className="text-3xl md:text-4xl font-chillax-bold mb-16 text-center text-white"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            Breakthrough Technologies
          </motion.h2>
          <div className="grid md:grid-cols-3 gap-12">
            {[
              { 
                title: 'Patented Dual-Piston System', 
                icon: Zap, 
                description: 'Our revolutionary system delivers precise force feedback, simulating various terrains and physical interactions with unprecedented realism.' 
              },
              { 
                title: 'Full-Body Haptic Feedback', 
                icon: Hand, 
                description: "ExoSuit VR envelops users in a comprehensive sensory experience, from subtle vibrations to substantial resistive forces." 
              },
              { 
                title: 'Advanced AI Integration', 
                icon: Brain, 
                description: 'Our multi-faceted AI enhances every aspect of the ExoSuit VR experience, from movement optimization to personalized training insights.' 
              }
            ].map((item, index) => (
              <motion.div
                key={index}
                className="bg-[#2a3540] p-8 rounded-lg shadow-lg"
                variants={fadeIn}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, margin: "-100px" }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="text-[#E67E22] mb-6">
                  <item.icon size={48} />
                </div>
                <h3 className="text-2xl font-chillax-bold mb-4 text-white">{item.title}</h3>
                <p className="text-[#f2f2f2] font-avenir leading-relaxed">{item.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* ExoSuit VR Experience Section */}
      <section className="bg-gradient-to-r from-[#1d2027] to-[#2a3540] py-24">
        <div className="container mx-auto px-4 md:px-6">
          <motion.h2 
            className="text-3xl md:text-4xl font-chillax-bold mb-16 text-white text-center"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            The ExoSuit VR Experience
          </motion.h2>
          <div className="grid md:grid-cols-2 gap-12 mb-16">
            {[
              { 
                title: 'Unparalleled Immersion', 
                icon: Zap,
                description: 'ExoSuit VR doesn\'t just show you virtual worlds – it lets you feel them. Our full-body haptic feedback system creates a level of immersion that must be experienced to be believed.' 
              },
              { 
                title: 'Precise Interaction', 
                icon: Hand,
                description: 'Interact with virtual objects as if they were real. Our dual-piston system provides realistic resistance and texture simulation, allowing for nuanced and natural interactions.' 
              },
              { 
                title: 'Adaptive Learning', 
                icon: Brain,
                description: 'ExoSuit VR learns from you. Our AI-driven system adapts to your movements and preferences, creating a personalized experience that becomes more intuitive over time.' 
              },
              { 
                title: 'Physical Comfort', 
                icon: Shield,
                description: 'Designed with ergonomics in mind, ExoSuit VR ensures comfort during extended use. Experience hours of immersive VR without the fatigue associated with traditional setups.' 
              }
            ].map((item, index) => (
              <motion.div
                key={index}
                className="bg-[#2a3540] p-8 rounded-lg shadow-lg"
                variants={fadeIn}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, margin: "-100px" }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="flex items-center mb-6">
                  <item.icon size={32} className="text-[#E67E22] mr-4" />
                  <h3 className="text-2xl font-chillax-bold text-white">{item.title}</h3>
                </div>
                <p className="text-[#f2f2f2] font-avenir leading-relaxed">{item.description}</p>
              </motion.div>
            ))}
          </div>
          
          {/* AI-Powered Features Subsection */}
          <motion.h3 
            className="text-2xl md:text-3xl font-chillax-bold mb-12 text-white text-center"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            AI-Powered Features
          </motion.h3>
          <div className="grid md:grid-cols-3 gap-12">
            {[
              { 
                title: 'Optimized Movements', 
                icon: Cog,
                description: "Our AI continuously fine-tunes the exoskeleton's movements, ensuring precise and realistic interactions within the virtual environment."
              },
              { 
                title: 'Intelligent Training Analysis', 
                icon: BarChart,
                description: 'Leverage AI-generated reports that provide deep insights into your training progress, highlighting areas of improvement and tracking your development over time.' 
              },
              { 
                title: 'Generative VR Content', 
                icon: Brain,
                description: 'Experience dynamically created VR scenarios powered by our generative AI models, trained on rich data collected from ExoSuit VR usage.' 
              }
            ].map((item, index) => (
              <motion.div
                key={index}
                className="bg-[#2a3540] p-8 rounded-lg shadow-lg"
                variants={fadeIn}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, margin: "-100px" }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="flex items-center mb-6">
                  <item.icon size={32} className="text-[#E67E22] mr-4" />
                  <h3 className="text-2xl font-chillax-bold text-white">{item.title}</h3>
                </div>
                <p className="text-[#f2f2f2] font-avenir leading-relaxed">{item.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-24 bg-[#1d2027]">
        <div className="container mx-auto px-4 md:px-6 text-center">
          <motion.div
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            <h2 className="text-3xl md:text-4xl font-chillax-bold mb-8 text-white">
              Experience the Future of AI-Enhanced VR
            </h2>
            <p className="text-xl mb-12 text-[#f2f2f2] font-avenir max-w-3xl mx-auto leading-relaxed">
              Discover how ExoSuit VR's advanced features and AI capabilities can revolutionize your training, enhance simulations, and create unparalleled immersive experiences. Step into a world where cutting-edge technology pushes the boundaries of what's possible in VR.
            </p>
            <Link to="/contact-us">
              <motion.button 
                className="bg-[#E67E22] hover:bg-[#D35400] text-white px-8 py-4 rounded-full text-lg font-chillax-bold transition-all duration-300 inline-block"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Get in Touch
              </motion.button>
            </Link>
          </motion.div>
        </div>
      </section>
    </>
  );
}

export default Home;