import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Cpu, ChevronRight, ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';

function Product() {
  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fadeIn = {
    hidden: { opacity: 0, y: 10 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 0.5, ease: "easeOut" } 
    },
  };

  return (
    <div className="bg-gradient-to-b from-[#1d2027] to-[#2a3540] min-h-screen text-white">
      <div className="container mx-auto px-4 py-24 max-w-7xl">
        <motion.h1 
          className="text-5xl md:text-6xl font-chillax-bold mb-6 text-white leading-tight"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          ExoSuit VR: The Future of Physical Interaction
        </motion.h1>
        <motion.p 
          className="text-xl md:text-2xl font-avenir text-[#f2f2f2] mb-12 max-w-3xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Transcend reality with our revolutionary VR exoskeleton. Experience unprecedented immersion across professional and entertainment sectors.
        </motion.p>

        <section className="mb-24">
          <motion.div 
            className="flex items-center mb-8"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            <div className="bg-[#E67E22] p-2 rounded-md mr-4">
              <Cpu size={24} className="text-white" />
            </div>
            <h2 className="text-3xl md:text-4xl font-chillax-bold text-white">Shaping the Future of Human-Machine Interaction</h2>
          </motion.div>
          
          <motion.div 
            className="bg-[#2a3540] p-8 rounded-2xl shadow-lg mb-12"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            <p className="text-lg text-[#f2f2f2] font-avenir mb-8 leading-relaxed">
              ExoSuit VR stands at the forefront of VR innovation, offering unparalleled precision and realism in virtual experiences. Our groundbreaking exoskeleton technology simulates force feedback, allowing you to feel and handle virtual objects as if they were real.
            </p>
            
            <h3 className="text-2xl font-chillax-bold mb-6 text-[#E67E22]">Key Innovations:</h3>
            <ul className="space-y-4 text-[#f2f2f2] font-avenir">
              {[
                { title: "Proprietary Dual-Piston System", description: "Experience the authentic weight, texture, and resistance of virtual objects." },
                { title: "Full-Body Exoskeleton", description: "Move freely and naturally within virtual environments." },
                { title: "Advanced AI Integration", description: "Engage in dynamic virtual scenarios that adapt to your movements and actions." },
                { title: "High-Fidelity Simulations", description: "Train safely for challenging real-world situations with realistic physical feedback." },
                { title: "Seamless VR Integration", description: "Enhance your current VR setups with the added dimension of physical touch and interaction." }
              ].map((innovation, index) => (
                <li key={index} className="flex items-start">
                  <ArrowRight className="text-[#E67E22] mr-3 mt-1 flex-shrink-0" size={20} />
                  <div>
                    <span className="font-semibold">{innovation.title}:</span> {innovation.description}
                  </div>
                </li>
              ))}
            </ul>
          </motion.div>
          
          <motion.div 
            className="bg-[#2a3540] p-8 rounded-2xl shadow-lg mb-12"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            <h3 className="text-2xl font-chillax-bold mb-6 text-[#E67E22]">Revolutionary Dual-Piston Technology</h3>
            <p className="text-md text-[#f2f2f2] font-avenir leading-relaxed mb-6">
              Our patented dual-piston system sets a new standard in force feedback and haptic sensations. It allows users to physically feel and interact with virtual objects and environments with unprecedented realism, from the subtle resistance of uneven landscapes to the solid feedback of virtual tools and weapons.
            </p>
            <ul className="space-y-4 text-[#f2f2f2] font-avenir">
              {[
                "Precise force simulation for a wide range of physical interactions",
                "Adaptive resistance to mimic various materials and surfaces",
                "Real-time haptic feedback for immersive experiences",
                "Enhanced proprioception for natural movement in VR",
                "Scalable force output for various applications",
                "Low-latency response for seamless user experience"
              ].map((feature, index) => (
                <li key={index} className="flex items-start">
                  <ArrowRight className="text-[#E67E22] mr-3 mt-1 flex-shrink-0" size={20} />
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
          </motion.div>

          <motion.div 
            className="bg-[#2a3540] p-8 rounded-2xl shadow-lg mb-12"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            <h3 className="text-2xl font-chillax-bold mb-6 text-[#E67E22]">Applications</h3>
            <ul className="space-y-6">
              {[
                { 
                  title: 'Military Training', 
                  description: 'Physically simulate complex combat scenarios and tactical operations. Feel the weight of equipment, the recoil of weapons, and navigate terrains with realistic resistance.' 
                },
                { 
                  title: 'Industrial Manufacturing', 
                  description: 'Enhance operational efficiency and safety training by physically interacting with virtual machinery and prototypes. Reduce the need for physical models through hands-on virtual prototyping.' 
                },
                { 
                  title: 'Entertainment', 
                  description: 'Create extraordinary immersive experiences where gamers and amusement park visitors can move and interact physically with virtual worlds, handling virtual objects with lifelike sensations.' 
                }
              ].map((item, index) => (
                <li key={index} className="flex items-start">
                  <ArrowRight className="text-[#E67E22] mr-3 mt-1 flex-shrink-0" size={20} />
                  <div>
                    <p className="font-semibold text-white">{item.title}</p>
                    <p className="text-sm text-[#f2f2f2]">{item.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </motion.div>

          <Link to="/product/details">
            <motion.button
              className="bg-[#E67E22] text-white px-8 py-4 rounded-full hover:bg-[#D35400] transition duration-300 flex items-center text-lg font-semibold"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Explore ExoSuit VR <ChevronRight className="ml-2" size={20} />
            </motion.button>
          </Link>
        </section>

        <div className="w-full h-px bg-gradient-to-r from-transparent via-[#E67E22] to-transparent my-24"></div>

        <section>
          <motion.h2 
            className="text-4xl font-chillax-bold text-white mb-12 text-center"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            Transforming Industries with ExoSuit VR
          </motion.h2>
          <div className="grid md:grid-cols-3 gap-10">
            {[
              {
                title: "Advanced Professional Training",
                description: "ExoSuit VR elevates training across high-stakes industries by allowing professionals to physically engage with virtual simulations. Whether performing intricate surgical procedures or responding to hazardous emergencies, users can practice with realistic force feedback, significantly enhancing performance and reducing real-world risks."
              },
              {
                title: "Revolutionary R&D and Prototyping",
                description: "ExoSuit VR redefines the R&D process by enabling engineers and designers to physically interact with virtual prototypes. This hands-on approach dramatically reduces the need for physical models, leading to substantial cost savings, accelerated development cycles, and enhanced product refinement through real-time physical feedback."
              },
              {
                title: "Next-Gen Immersive Entertainment",
                description: "In the entertainment sector, ExoSuit VR opens new frontiers by allowing users to move and interact physically within virtual environments. Gamers can handle virtual weapons, tools, and artifacts with lifelike sensations, while amusement park visitors experience levels of immersion and engagement previously unimaginable."
              }
            ].map((item, index) => (
              <motion.div
                key={index}
                className="bg-[#2a3540] p-8 rounded-2xl shadow-lg"
                variants={fadeIn}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, margin: "-100px" }}
              >
                <h3 className="text-2xl font-chillax-bold mb-4 text-white">{item.title}</h3>
                <p className="text-md text-[#f2f2f2] font-avenir leading-relaxed">{item.description}</p>
              </motion.div>
            ))}
          </div>
        </section>

        <motion.div 
          className="mt-24 p-10 bg-[#2a3540] rounded-2xl shadow-xl"
          variants={fadeIn}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          <h3 className="text-3xl font-chillax-bold mb-6 text-white">Experience the Future of VR with ExoSuit</h3>
          <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed mb-8">
            ExoSuit VR embodies our commitment to pushing the boundaries of VR technology. Through continuous innovation and user-centric design, we're not just improving virtual reality—we're redefining how humans move and interact within digital environments. Join us in bridging the gap between the physical and virtual worlds, and be part of the next great leap in human-machine interaction.
          </p>
          <Link to="/contact-us">
            <motion.button
              className="bg-[#E67E22] text-white px-8 py-4 rounded-full text-lg font-chillax-bold transition-all duration-300"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Get in Touch
            </motion.button>
          </Link>
        </motion.div>
      </div>
    </div>
  );
}

export default Product;