import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Mail, 
  MapPin, 
  Phone, 
  ArrowRight, 
  Linkedin, 
  Twitter, 
  Youtube,
  ArrowUpRight,
} from 'lucide-react';

function Footer() {
  const navigate = useNavigate();

  const handleIndustryClick = (sectionId) => {
    navigate('/exoskeleton-applications');
    setTimeout(() => {
      const element = document.querySelector(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const quickLinks = [
    { to: '/', label: 'Home' },
    { to: '/product', label: 'Product' },
    { to: '/product/details', label: 'Product Details' },
    { to: '/team', label: 'Team' },
    { to: '/vision', label: 'Vision' },
    { to: '/exoskeleton-applications', label: 'Applications' },
    { to: '/contact-us', label: 'Contact' }
  ];

  const industryLinks = [
    { 
      label: 'Military & Security',
      onClick: () => handleIndustryClick('#military-security')
    },
    { 
      label: 'Industrial Manufacturing',
      onClick: () => handleIndustryClick('#industrial-manufacturing')
    },
    { 
      label: 'Gaming & Entertainment',
      onClick: () => handleIndustryClick('#gaming-entertainment')
    },
    { 
      label: 'Healthcare & Medical',
      onClick: () => handleIndustryClick('#healthcare-medical')
    }
  ];

  const socialLinks = [
    { icon: Linkedin, label: 'LinkedIn', url: '#' },
    { icon: Twitter, label: 'Twitter', url: '#' },
    { icon: Youtube, label: 'YouTube', url: '#' }
  ];

  return (
    <footer className="bg-gradient-to-b from-[#1d2027] to-[#2a3540] py-16 px-6 font-avenir">
      <div className="container mx-auto max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 mb-16">
          {/* Company Info */}
          <div className="space-y-6">
            <Link to="/" className="inline-block">
              <h2 className="text-3xl font-chillax-bold text-white hover:text-[#E67E22] transition-colors duration-200">
                newton
              </h2>
            </Link>
            <p className="text-[#9E9E9E] leading-relaxed">
              Pioneering the future of physical interaction in virtual reality. Our innovative exoskeleton technology 
              is transforming how humans experience and interact with digital worlds.
            </p>
            <div className="flex space-x-4">
              {socialLinks.map((social, index) => (
                <a
                  key={index}
                  href={social.url}
                  className="text-[#9E9E9E] hover:text-[#E67E22] transition-colors duration-200"
                  aria-label={social.label}
                >
                  <social.icon size={20} />
                </a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          <div className="space-y-6">
            <h3 className="text-xl font-chillax-bold text-white">Navigation</h3>
            <nav>
              <ul className="space-y-3">
                {quickLinks.map((link) => (
                  <li key={link.label}>
                    <Link
                      to={link.to}
                      className="text-[#9E9E9E] hover:text-[#E67E22] transition-colors duration-200 flex items-center group"
                    >
                      <ArrowRight className="mr-2 h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity duration-200" />
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          {/* Industry Applications */}
          <div className="space-y-6">
            <h3 className="text-xl font-chillax-bold text-white">Industry Solutions</h3>
            <ul className="space-y-3">
              {industryLinks.map((industry, index) => (
                <li key={index}>
                  <button
                    onClick={industry.onClick}
                    className="text-[#9E9E9E] hover:text-[#E67E22] transition-colors duration-200 flex items-center group w-full text-left"
                  >
                    <ArrowUpRight className="mr-2 h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity duration-200" />
                    {industry.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div className="space-y-6">
            <h3 className="text-xl font-chillax-bold text-white">Contact Us</h3>
            <address className="not-italic space-y-4">
              <a
                href="mailto:info@newtonxr.com"
                className="flex items-center text-[#9E9E9E] hover:text-[#E67E22] transition-colors duration-200"
              >
                <Mail className="mr-3 h-5 w-5" />
                info@newtonxr.com
              </a>
              <div className="flex items-center text-[#9E9E9E]">
                <MapPin className="mr-3 h-5 w-5" />
                Tel Aviv, Israel
              </div>
              <a
                href="tel:+972559572906"
                className="flex items-center text-[#9E9E9E] hover:text-[#E67E22] transition-colors duration-200"
              >
                <Phone className="mr-3 h-5 w-5" />
                +972 559572906
              </a>
            </address>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-[#FFFFFF]/10 pt-8 text-center">
          <p className="text-[#9E9E9E] text-sm">
            &copy; {new Date().getFullYear()} Newton Technologies. All rights reserved.
            <br className="sm:hidden" />
            <span className="hidden sm:inline"> | </span>
            Pioneering the Future of VR Interaction
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;