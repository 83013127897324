// import React, { useState, useEffect } from 'react';
// import { X, ChevronDown } from 'lucide-react'; // Importando ChevronDown

// function Team() {
//   const [selectedMember, setSelectedMember] = useState(null);
  
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const teamMembers = [
//     {
//       name: 'David Stahl',
//       role: 'CEO & Founder',
//       image: '/images/david-stahl.jpg',
//       bio: 'David holds a dual degree in Economics and Business from the Hebrew University of Jerusalem, specializing in Startup Development and Machine Learning Techniques. As the visionary founder of Newton, he leads the company\'s strategic direction and business growth.',
//       expandedInfo: {
//         background: 'David\'s journey as an innovator began remarkably early - at age 15, he conceived and filed a patent for groundbreaking exoskeleton technology for virtual reality interaction, which was successfully granted in 2022. Originally from Brazil, his entrepreneurial spirit manifested early when he founded Evolve Midias, a music and event production company, while still a teenager. After moving to Israel in 2017, he pursued a dual degree in Economics and Business Administration at the Hebrew University of Jerusalem, combining academic excellence with his natural entrepreneurial drive.',
//         innovations: [
//           'Conceived VR exoskeleton technology at age 15 (2013)',
//           'Successfully obtained patent approval (2022)',
//           'Pioneered new approaches to virtual reality interaction',
//           'Developed innovative business models for VR technology commercialization'
//         ],
//         expertise: [
//           'Machine Learning and Big Data Analytics',
//           'Reinforcement Learning',
//           'Neurolinguistic Programming and Conversational Hypnosis',
//           'Strategic Business Development',
//           'Technology Innovation Management'
//         ],
//         entrepreneurialHistory: [
//           'Founded Newton Technologies (2023-Present)',
//           'Founded Evolve Midias - Music and Event Production Company (2015-2016)',
//           'Filed VR Exoskeleton Patent (2013)',
//           'Patent Approval Secured (2022)'
//         ],
//         education: [
//           'Dual Bachelor\'s Degree in Economics and Business Administration - Hebrew University of Jerusalem (Final Semester)',
//           'Specialization in Startup Development',
//           'Advanced studies in Machine Learning for Big Data and Data Analysis'
//         ],
//         languages: 'Native Portuguese speaker, Full Professional Proficiency in Hebrew and English, Fluent in Spanish',
//         visionAndLeadership: [
//           'Transforming virtual reality interactions through innovative exoskeleton technology',
//           'Leading cross-cultural team development and management',
//           'Bridging technological innovation with market opportunities',
//           'Developing strategic partnerships across industries',
//           'Driving the company\'s vision for future growth and innovation'
//         ],
//         responsibilities: [
//           'Leading overall company strategy and vision',
//           'Managing investor relations and strategic partnerships',
//           'Overseeing product development direction',
//           'Building and leading high-performance teams',
//           'Driving innovation and technological advancement'
//         ]
//       }
//     },
//     {
//       name: 'Bruno Nigri',
//       role: 'CTO',
//       image: '/images/bruno-nigri.jpg',
//       bio: 'Bruno\'s expertise lies in software engineering, particularly in C/C++ and embedded systems. With a background in Computer Software Engineering and Mechatronics, he leads Newton\'s technological advancements and product innovation.',
//       expandedInfo: {
//         background: 'As an experienced Software Engineer, Bruno brings extensive expertise in embedded systems and real-time environments. His dual educational background in Computer Software Engineering from Lev Academic Center and Mechatronics, Robotics, and Automation Engineering from Universidade Presbiteriana Mackenzie provides a unique perspective that combines software excellence with mechanical systems understanding.',
//         expertise: [
//           'Advanced C/C++ programming',
//           'Embedded software development',
//           'Real-time systems and microcontroller programming',
//           'Software architecture and clean coding',
//           'Problem-solving and system optimization'
//         ],
//         professionalHighlights: [
//           'Developed physical cybersecurity solutions in real-time embedded environments at Fibernet Ltd.',
//           'Created three dental laser devices with hard real-time embedded systems at Light Instruments Ltd.',
//           'Achieved 50% speed increase in laser energy calibration through automation',
//           'Implemented microsecond-precise timer systems for laser pulse control'
//         ],
//         education: [
//           'Bachelor of Engineering in Computer Software Engineering - Lev Academic Center, Jerusalem',
//           'Bachelor of Engineering in Mechatronics, Robotics, and Automation - Universidade Presbiteriana Mackenzie, São Paulo'
//         ],
//         languages: 'Native Portuguese speaker, with full professional proficiency in Hebrew and English, and professional working proficiency in Spanish',
//         responsibilities: [
//           'Leading technological strategy and software development',
//           'Overseeing embedded systems architecture and implementation',
//           'Managing real-time system optimization and performance',
//           'Directing software team and development processes',
//           'Ensuring code quality and technical excellence'
//         ]
//       }
//     },
//     {
//       name: 'Lucas Triginelli',
//       role: 'Chief Product Development Officer',
//       image: 'images/lucas.jpg',
//       bio: 'Lucas specializes in mechanical design and product development. With a Bachelor\'s degree in Mechanical Engineering, he oversees all aspects of Newton\'s product design and development processes.',
//       expandedInfo: {
//         background: 'With over 5 years of experience in mechanical engineering, Lucas brings extensive expertise in mechanical design, product development, and testing. Originally from Brazil and now based in Israel for 9 years, his international background enriches our cross-cultural and multidisciplinary engineering environment. His track record includes successful product launches at Duma Optronics and significant achievements at Dentaray, where he achieved a 40% reduction in product size through mechanical design optimization.',
//         expertise: [
//           'Solidworks and mechanical design',
//           'Product development from concept to production',
//           'Tolerance analysis and materials expertise',
//           'Opto-mechanical assembly processes',
//           'Cross-cultural team collaboration'
//         ],
//         professionalHistory: [
//           'Senior Mechanical Engineer at Duma Optronics (2022-Present)',
//           'Mechanical Engineer at Dentaray (2018-2022)',
//           'Junior Mechanical Engineer at GOL Linhas Aéreas (2012-2013)'
//         ],
//         education: 'Bachelor\'s Degree in Mechanical Engineering from Centro Universitario Newton Paiva, Brazil',
//         languages: 'Fluent in Portuguese, English, Spanish, and Hebrew',
//         responsibilities: [
//           'Leading mechanical design and product development initiatives',
//           'Overseeing prototype development and testing',
//           'Managing manufacturing processes and quality control',
//           'Coordinating with cross-functional engineering teams',
//           'Driving innovation in mechanical systems and processes'
//         ]
//       }
//     },
//     {
//       name: 'Dan Edery',
//       role: 'Chief Operations Officer',
//       image: '/images/dan-edery.jpg',
//       bio: 'Dan brings insights from his experience in construction, housing, and transportation sectors. With a degree in Business Administration and Sustainability from the Hebrew University of Jerusalem, he manages Newton\'s operational strategies and execution.',
//       expandedInfo: {
//         background: 'Dan brings a unique blend of government sector experience and innovation management to Newton. As a former Assistant to the CEO at the Ministry of Construction and Housing and Project Manager at the Ministry of Transportation, he has demonstrated expertise in leading complex initiatives and managing strategic projects. His experience in the Navy\'s Airborne Unit has cultivated strong leadership skills and precision in execution.',
//         expertise: [
//           'Strategic project management',
//           'Innovation and industrialization leadership',
//           'Infrastructure coordination',
//           'Business intelligence and analytics',
//           'Regulatory and process optimization'
//         ],
//         professionalHighlights: [
//           'Led industrialization and innovation initiatives at the Ministry of Construction and Housing',
//           'Managed national infrastructure projects at the Ministry of Transportation',
//           'Participated in task forces for urban renewal and infrastructure development',
//           'Developed business intelligence databases for policy-making',
//           'Military service in Navy\'s Airborne Unit with intelligence operations'
//         ],
//         education: [
//           'Bachelor\'s degree in Business Administration - Hebrew University of Jerusalem',
//           'Bachelor\'s degree in Sustainability and Multidisciplinary Studies - Hebrew University of Jerusalem (Final Semester)',
//           'Graduate of The High School next to the Hebrew University, Jerusalem'
//         ],
//         skills: [
//           'Advanced computer and organizational skills',
//           'Proficiency in Excel, Office, Outlook, and Salesforce',
//           'Strong interpersonal and management abilities',
//           'Strategic planning and execution',
//           'Cross-functional team coordination'
//         ],
//         volunteerWork: [
//           'Mentor at Wings of Krembo - Supporting children with special needs',
//           'Volunteer at Bells Organization - Financial guidance for families'
//         ],
//         languages: 'Native Hebrew speaker with full proficiency in English',
//         responsibilities: [
//           'Leading operational strategy and execution',
//           'Managing innovation and process optimization initiatives',
//           'Coordinating cross-functional team activities',
//           'Overseeing project management and implementation',
//           'Developing and maintaining strategic partnerships'
//         ]
//       }
//     },
//   ];

//   return (
//     <div className="container mx-auto px-6 py-24">
//       <h1 className="text-3xl font-chillax-bold mb-4 text-[#FFFFFF]">Our Team</h1>
//       <p className="text-lg font-avenir text-[#f2f2f2] mb-12">The visionaries behind Newton's revolutionary VR technology</p>
//       <div className="grid md:grid-cols-2 gap-12">
//         {teamMembers.map((member, index) => (
//           <div 
//             key={index} 
//             onClick={() => setSelectedMember(member)}
//             className="relative bg-[#1d2027] p-8 rounded-lg shadow-lg flex items-start gap-6 transition-all duration-300 hover:shadow-xl hover:bg-[#2a3540] transform hover:-translate-y-2 hover:scale-105 cursor-pointer group"
//           >
//             <img 
//               src={member.image} 
//               alt={member.name} 
//               className="w-32 h-32 rounded-full object-cover flex-shrink-0"
//             />
//             <div>
//               <h2 className="text-xl font-chillax-bold text-[#FFFFFF] mb-1">{member.name}</h2>
//               <p className="text-sm text-[#E67E22] font-chillax-medium mb-3">{member.role}</p>
//               <p className="text-sm text-[#f2f2f2] font-avenir">{member.bio}</p>
//             </div>
//             {/* Seta indicativa de expansão no canto inferior direito */}
//             <div className="absolute bottom-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
//               <ChevronDown 
//                 size={24} 
//                 color="#FFFFFF" 
//                 className="animate-bounce cursor-pointer" 
//               />
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Overlay */}
//       {selectedMember && (
//         <div
//           className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50"
//           onClick={() => setSelectedMember(null)} // Fecha o modal ao clicar fora
//         >
//           <div
//             className="bg-[#1d2027] rounded-xl max-w-3xl w-full max-h-[90vh] overflow-y-auto relative [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:bg-[#1d2027] [&::-webkit-scrollbar-track]:rounded-r-xl [&::-webkit-scrollbar-thumb]:bg-[#3a4550] [&::-webkit-scrollbar-thumb]:rounded-full hover:[&::-webkit-scrollbar-thumb]:bg-[#4a5560] [&::-webkit-scrollbar-thumb]:transition-colors"
//             onClick={(e) => e.stopPropagation()} // Impede o fechamento ao clicar dentro do modal
//           >
//             <button 
//               onClick={(e) => {
//                 e.stopPropagation();
//                 setSelectedMember(null);
//               }}
//               className="sticky top-4 float-right mr-4 bg-[#2a3540] hover:bg-[#3a4550] text-[#f2f2f2] hover:text-white p-2 rounded-full shadow-lg transition-all duration-300 transform hover:scale-110"
//             >
//               <X size={24} />
//             </button>
//             <div className="p-8 clear-right">
              
//               <div className="flex items-start mb-6">
//                 <div className="flex items-center gap-6">
//                   <img 
//                     src={selectedMember.image} 
//                     alt={selectedMember.name}
//                     className="w-32 h-32 rounded-full object-cover"
//                   />
//                   <div>
//                     <h2 className="text-2xl font-chillax-bold text-[#FFFFFF]">{selectedMember.name}</h2>
//                     <p className="text-[#E67E22] font-chillax-medium">{selectedMember.role}</p>
//                   </div>
//                 </div>
//               </div>

//               <div className="space-y-6 text-[#f2f2f2] font-avenir">
//                 <div>
//                   <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Background</h3>
//                   <p>{selectedMember.expandedInfo.background}</p>
//                 </div>

//                 {selectedMember.expandedInfo.expertise && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Areas of Expertise</h3>
//                     <ul className="list-disc pl-6 space-y-2">
//                       {selectedMember.expandedInfo.expertise.map((skill, idx) => (
//                         <li key={idx}>{skill}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 )}

//                 {selectedMember.expandedInfo.professionalHighlights && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Professional Highlights</h3>
//                     <ul className="list-disc pl-6 space-y-2">
//                       {selectedMember.expandedInfo.professionalHighlights.map((highlight, idx) => (
//                         <li key={idx}>{highlight}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 )}

//                 {selectedMember.expandedInfo.professionalHistory && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Professional History</h3>
//                     <ul className="list-disc pl-6 space-y-2">
//                       {selectedMember.expandedInfo.professionalHistory.map((position, idx) => (
//                         <li key={idx}>{position}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 )}

//                 {selectedMember.expandedInfo.education && Array.isArray(selectedMember.expandedInfo.education) ? (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Education</h3>
//                     <ul className="list-disc pl-6 space-y-2">
//                       {selectedMember.expandedInfo.education.map((edu, idx) => (
//                         <li key={idx}>{edu}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 ) : selectedMember.expandedInfo.education && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Education</h3>
//                     <p>{selectedMember.expandedInfo.education}</p>
//                   </div>
//                 )}

//                 {selectedMember.expandedInfo.languages && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Languages</h3>
//                     <p>{selectedMember.expandedInfo.languages}</p>
//                   </div>
//                 )}

//                 <div>
//                   <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Key Responsibilities</h3>
//                   <ul className="list-disc pl-6 space-y-2">
//                     {selectedMember.expandedInfo.responsibilities.map((resp, idx) => (
//                       <li key={idx}>{resp}</li>
//                     ))}
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default Team;


// import React, { useState, useEffect } from 'react';
// import { X, ChevronDown } from 'lucide-react';

// function Team() {
//   const [selectedMember, setSelectedMember] = useState(null);
  
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const teamMembers = [
//     {
//       name: 'David Stahl',
//       role: 'CEO & Founder',
//       image: '/images/david-stahl.jpg',
//       bio: 'David holds a dual degree in Economics and Business from the Hebrew University of Jerusalem, specializing in Startup Development and Machine Learning Techniques. As the visionary founder of Newton, he leads the company\'s strategic direction and business growth.',
//       expandedInfo: {
//         background: 'David\'s journey as an innovator began remarkably early - at age 15, he conceived and filed a patent for groundbreaking exoskeleton technology for virtual reality interaction, which was successfully granted in 2022. Originally from Brazil, his entrepreneurial spirit manifested early when he founded Evolve Midias, a music and event production company, while still a teenager. After moving to Israel in 2017, he pursued a dual degree in Economics and Business Administration at the Hebrew University of Jerusalem, combining academic excellence with his natural entrepreneurial drive.',
//         innovations: [
//           'Conceived VR exoskeleton technology at age 15 (2013)',
//           'Successfully obtained patent approval (2022)',
//           'Pioneered new approaches to virtual reality interaction',
//           'Developed innovative business models for VR technology commercialization'
//         ],
//         expertise: [
//           'Machine Learning and Big Data Analytics',
//           'Reinforcement Learning',
//           'Neurolinguistic Programming and Conversational Hypnosis',
//           'Strategic Business Development',
//           'Technology Innovation Management'
//         ],
//         entrepreneurialHistory: [
//           'Founded Newton Technologies (2023-Present)',
//           'Founded Evolve Midias - Music and Event Production Company (2015-2016)',
//           'Filed VR Exoskeleton Patent (2013)',
//           'Patent Approval Secured (2022)'
//         ],
//         education: [
//           'Dual Bachelor\'s Degree in Economics and Business Administration - Hebrew University of Jerusalem (Final Semester)',
//           'Specialization in Startup Development',
//           'Advanced studies in Machine Learning for Big Data and Data Analysis'
//         ],
//         languages: 'Native Portuguese speaker, Full Professional Proficiency in Hebrew and English, Fluent in Spanish',
//         visionAndLeadership: [
//           'Transforming virtual reality interactions through innovative exoskeleton technology',
//           'Leading cross-cultural team development and management',
//           'Bridging technological innovation with market opportunities',
//           'Developing strategic partnerships across industries',
//           'Driving the company\'s vision for future growth and innovation'
//         ],
//         responsibilities: [
//           'Leading overall company strategy and vision',
//           'Managing investor relations and strategic partnerships',
//           'Overseeing product development direction',
//           'Building and leading high-performance teams',
//           'Driving innovation and technological advancement'
//         ]
//       }
//     },
//     {
//       name: 'Bruno Nigri',
//       role: 'CTO',
//       image: '/images/bruno-nigri.jpg',
//       bio: 'Bruno\'s expertise lies in software engineering, particularly in C/C++ and embedded systems. With a background in Computer Software Engineering and Mechatronics, he leads Newton\'s technological advancements and product innovation.',
//       expandedInfo: {
//         background: 'As an experienced Software Engineer, Bruno brings extensive expertise in embedded systems and real-time environments. His dual educational background in Computer Software Engineering from Lev Academic Center and Mechatronics, Robotics, and Automation Engineering from Universidade Presbiteriana Mackenzie provides a unique perspective that combines software excellence with mechanical systems understanding.',
//         expertise: [
//           'Advanced C/C++ programming',
//           'Embedded software development',
//           'Real-time systems and microcontroller programming',
//           'Software architecture and clean coding',
//           'Problem-solving and system optimization'
//         ],
//         professionalHighlights: [
//           'Developed physical cybersecurity solutions in real-time embedded environments at Fibernet Ltd.',
//           'Created three dental laser devices with hard real-time embedded systems at Light Instruments Ltd.',
//           'Achieved 50% speed increase in laser energy calibration through automation',
//           'Implemented microsecond-precise timer systems for laser pulse control'
//         ],
//         education: [
//           'Bachelor of Engineering in Computer Software Engineering - Lev Academic Center, Jerusalem',
//           'Bachelor of Engineering in Mechatronics, Robotics, and Automation - Universidade Presbiteriana Mackenzie, São Paulo'
//         ],
//         languages: 'Native Portuguese speaker, with full professional proficiency in Hebrew and English, and professional working proficiency in Spanish',
//         responsibilities: [
//           'Leading technological strategy and software development',
//           'Overseeing embedded systems architecture and implementation',
//           'Managing real-time system optimization and performance',
//           'Directing software team and development processes',
//           'Ensuring code quality and technical excellence'
//         ]
//       }
//     },
//     {
//       name: 'Lucas Triginelli',
//       role: 'Chief Product Development Officer',
//       image: 'images/lucas.jpg',
//       bio: 'Lucas specializes in mechanical design and product development. With a Bachelor\'s degree in Mechanical Engineering, he oversees all aspects of Newton\'s product design and development processes.',
//       expandedInfo: {
//         background: 'With over 5 years of experience in mechanical engineering, Lucas brings extensive expertise in mechanical design, product development, and testing. Originally from Brazil and now based in Israel for 9 years, his international background enriches our cross-cultural and multidisciplinary engineering environment. His track record includes successful product launches at Duma Optronics and significant achievements at Dentaray, where he achieved a 40% reduction in product size through mechanical design optimization.',
//         expertise: [
//           'Solidworks and mechanical design',
//           'Product development from concept to production',
//           'Tolerance analysis and materials expertise',
//           'Opto-mechanical assembly processes',
//           'Cross-cultural team collaboration'
//         ],
//         professionalHistory: [
//           'Senior Mechanical Engineer at Duma Optronics (2022-Present)',
//           'Mechanical Engineer at Dentaray (2018-2022)',
//           'Junior Mechanical Engineer at GOL Linhas Aéreas (2012-2013)'
//         ],
//         education: 'Bachelor\'s Degree in Mechanical Engineering from Centro Universitario Newton Paiva, Brazil',
//         languages: 'Fluent in Portuguese, English, Spanish, and Hebrew',
//         responsibilities: [
//           'Leading mechanical design and product development initiatives',
//           'Overseeing prototype development and testing',
//           'Managing manufacturing processes and quality control',
//           'Coordinating with cross-functional engineering teams',
//           'Driving innovation in mechanical systems and processes'
//         ]
//       }
//     }
//   ];

//   return (
//     <div className="container mx-auto px-6 py-24">
//       <h1 className="text-3xl font-chillax-bold mb-4 text-[#FFFFFF] text-center">Our Team</h1>
//       <p className="text-lg font-avenir text-[#f2f2f2] mb-12 text-center">The visionaries behind Newton's revolutionary VR technology</p>
//       <div className="flex flex-col lg:flex-row gap-8 max-w-7xl mx-auto">
//         {teamMembers.map((member, index) => (
//           <div 
//             key={index} 
//             onClick={() => setSelectedMember(member)}
//             className="relative bg-[#1d2027] p-8 rounded-lg shadow-lg flex flex-col items-center transition-all duration-300 hover:shadow-xl hover:bg-[#2a3540] transform hover:-translate-y-2 hover:scale-105 cursor-pointer group flex-1"
//           >
//             <img 
//               src={member.image} 
//               alt={member.name} 
//               className="w-40 h-40 rounded-full object-cover mb-6"
//             />
//             <div className="text-center">
//               <h2 className="text-2xl font-chillax-bold text-[#FFFFFF] mb-2">{member.name}</h2>
//               <p className="text-lg text-[#E67E22] font-chillax-medium mb-4">{member.role}</p>
//               <p className="text-sm text-[#f2f2f2] font-avenir">{member.bio}</p>
//             </div>
//             <div className="absolute bottom-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
//               <ChevronDown 
//                 size={24} 
//                 color="#E67E22" 
//                 className="animate-bounce cursor-pointer" 
//               />
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Modal Overlay */}
//       {selectedMember && (
//         <div
//           className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50"
//           onClick={() => setSelectedMember(null)}
//         >
//           <div
//             className="bg-[#1d2027] rounded-xl max-w-3xl w-full max-h-[90vh] overflow-y-auto relative [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:bg-[#1d2027] [&::-webkit-scrollbar-track]:rounded-r-xl [&::-webkit-scrollbar-thumb]:bg-[#3a4550] [&::-webkit-scrollbar-thumb]:rounded-full hover:[&::-webkit-scrollbar-thumb]:bg-[#4a5560] [&::-webkit-scrollbar-thumb]:transition-colors"
//             onClick={(e) => e.stopPropagation()}
//           >
//             <button 
//               onClick={(e) => {
//                 e.stopPropagation();
//                 setSelectedMember(null);
//               }}
//               className="sticky top-4 float-right mr-4 bg-[#2a3540] hover:bg-[#3a4550] text-[#f2f2f2] hover:text-white p-2 rounded-full shadow-lg transition-all duration-300 transform hover:scale-110"
//             >
//               <X size={24} />
//             </button>
//             <div className="p-8 clear-right">
              
//               <div className="flex items-start mb-6">
//                 <div className="flex items-center gap-6">
//                   <img 
//                     src={selectedMember.image} 
//                     alt={selectedMember.name}
//                     className="w-32 h-32 rounded-full object-cover"
//                   />
//                   <div>
//                     <h2 className="text-2xl font-chillax-bold text-[#FFFFFF]">{selectedMember.name}</h2>
//                     <p className="text-[#E67E22] font-chillax-medium">{selectedMember.role}</p>
//                   </div>
//                 </div>
//               </div>

//               <div className="space-y-6 text-[#f2f2f2] font-avenir">
//                 <div>
//                   <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Background</h3>
//                   <p>{selectedMember.expandedInfo.background}</p>
//                 </div>

//                 {selectedMember.expandedInfo.expertise && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Areas of Expertise</h3>
//                     <ul className="list-disc pl-6 space-y-2">
//                       {selectedMember.expandedInfo.expertise.map((skill, idx) => (
//                         <li key={idx}>{skill}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 )}

//                 {selectedMember.expandedInfo.professionalHighlights && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Professional Highlights</h3>
//                     <ul className="list-disc pl-6 space-y-2">
//                       {selectedMember.expandedInfo.professionalHighlights.map((highlight, idx) => (
//                         <li key={idx}>{highlight}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 )}

//                 {selectedMember.expandedInfo.professionalHistory && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Professional History</h3>
//                     <ul className="list-disc pl-6 space-y-2">
//                       {selectedMember.expandedInfo.professionalHistory.map((position, idx) => (
//                         <li key={idx}>{position}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 )}

//                 {selectedMember.expandedInfo.education && Array.isArray(selectedMember.expandedInfo.education) ? (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Education</h3>
//                     <ul className="list-disc pl-6 space-y-2">
//                       {selectedMember.expandedInfo.education.map((edu, idx) => (
//                         <li key={idx}>{edu}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 ) : selectedMember.expandedInfo.education && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Education</h3>
//                     <p>{selectedMember.expandedInfo.education}</p>
//                   </div>
//                 )}

//                 {selectedMember.expandedInfo.languages && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Languages</h3>
//                     <p>{selectedMember.expandedInfo.languages}</p>
//                   </div>
//                 )}

//                 <div>
//                   <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Key Responsibilities</h3>
//                   <ul className="list-disc pl-6 space-y-2">
//                     {selectedMember.expandedInfo.responsibilities.map((resp, idx) => (
//                       <li key={idx}>{resp}</li>
//                     ))}
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default Team;


// import React, { useState, useEffect } from 'react';
// import { X, ChevronDown } from 'lucide-react';

// function Team() {
//   const [selectedMember, setSelectedMember] = useState(null);
  
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const teamMembers = [
//     {
//       name: 'David Stahl',
//       role: 'CEO & Founder',
//       image: '/images/david.jpg',
//       bio: 'David holds a dual degree in Economics and Business from the Hebrew University of Jerusalem, specializing in Startup Development and Machine Learning Techniques. As the visionary founder of Newton, he leads the company\'s strategic direction and business growth.',
//       expandedInfo: {
//         background: 'David\'s journey as an innovator began remarkably early - at age 15, he conceived and filed a patent for groundbreaking exoskeleton technology for virtual reality interaction, which was successfully granted in 2022. Originally from Brazil, his entrepreneurial spirit manifested early when he founded Evolve Midias, a music and event production company, while still a teenager. After moving to Israel in 2017, he pursued a dual degree in Economics and Business Administration at the Hebrew University of Jerusalem, combining academic excellence with his natural entrepreneurial drive.',
//         innovations: [
//           'Conceived VR exoskeleton technology at age 15 (2013)',
//           'Successfully obtained patent approval (2022)',
//           'Pioneered new approaches to virtual reality interaction',
//           'Developed innovative business models for VR technology commercialization'
//         ],
//         expertise: [
//           'Machine Learning and Big Data Analytics',
//           'Reinforcement Learning',
//           'Neurolinguistic Programming and Conversational Hypnosis',
//           'Strategic Business Development',
//           'Technology Innovation Management'
//         ],
//         entrepreneurialHistory: [
//           'Founded Newton Technologies (2023-Present)',
//           'Founded Evolve Midias - Music and Event Production Company (2015-2016)',
//           'Filed VR Exoskeleton Patent (2013)',
//           'Patent Approval Secured (2022)'
//         ],
//         education: [
//           'Dual Bachelor\'s Degree in Economics and Business Administration - Hebrew University of Jerusalem (Final Semester)',
//           'Specialization in Startup Development',
//           'Advanced studies in Machine Learning for Big Data and Data Analysis'
//         ],
//         languages: 'Native Portuguese speaker, Full Professional Proficiency in Hebrew and English, Fluent in Spanish',
//         visionAndLeadership: [
//           'Transforming virtual reality interactions through innovative exoskeleton technology',
//           'Leading cross-cultural team development and management',
//           'Bridging technological innovation with market opportunities',
//           'Developing strategic partnerships across industries',
//           'Driving the company\'s vision for future growth and innovation'
//         ],
//         responsibilities: [
//           'Leading overall company strategy and vision',
//           'Managing investor relations and strategic partnerships',
//           'Overseeing product development direction',
//           'Building and leading high-performance teams',
//           'Driving innovation and technological advancement'
//         ]
//       }
//     },
//     {
//       name: 'Bruno Nigri',
//       role: 'CTO',
//       image: '/images/bruno-nigri.jpg',
//       bio: 'Bruno\'s expertise lies in software engineering, particularly in C/C++ and embedded systems. With a background in Computer Software Engineering and Mechatronics, he leads Newton\'s technological advancements and product innovation.',
//       expandedInfo: {
//         background: 'As an experienced Software Engineer, Bruno brings extensive expertise in embedded systems and real-time environments. His dual educational background in Computer Software Engineering from Lev Academic Center and Mechatronics, Robotics, and Automation Engineering from Universidade Presbiteriana Mackenzie provides a unique perspective that combines software excellence with mechanical systems understanding.',
//         expertise: [
//           'Advanced C/C++ programming',
//           'Embedded software development',
//           'Real-time systems and microcontroller programming',
//           'Software architecture and clean coding',
//           'Problem-solving and system optimization'
//         ],
//         professionalHighlights: [
//           'Developed physical cybersecurity solutions in real-time embedded environments at Fibernet Ltd.',
//           'Created three dental laser devices with hard real-time embedded systems at Light Instruments Ltd.',
//           'Achieved 50% speed increase in laser energy calibration through automation',
//           'Implemented microsecond-precise timer systems for laser pulse control'
//         ],
//         education: [
//           'Bachelor of Engineering in Computer Software Engineering - Lev Academic Center, Jerusalem',
//           'Bachelor of Engineering in Mechatronics, Robotics, and Automation - Universidade Presbiteriana Mackenzie, São Paulo'
//         ],
//         languages: 'Native Portuguese speaker, with full professional proficiency in Hebrew and English, and professional working proficiency in Spanish',
//         responsibilities: [
//           'Leading technological strategy and software development',
//           'Overseeing embedded systems architecture and implementation',
//           'Managing real-time system optimization and performance',
//           'Directing software team and development processes',
//           'Ensuring code quality and technical excellence'
//         ]
//       }
//     },
//     {
//       name: 'Lucas Triginelli',
//       role: 'Chief Product Development Officer',
//       image: 'images/lucas.jpg',
//       bio: 'Lucas specializes in mechanical design and product development. With a Bachelor\'s degree in Mechanical Engineering, he oversees all aspects of Newton\'s product design and development processes.',
//       expandedInfo: {
//         background: 'With over 5 years of experience in mechanical engineering, Lucas brings extensive expertise in mechanical design, product development, and testing. Originally from Brazil and now based in Israel for 9 years, his international background enriches our cross-cultural and multidisciplinary engineering environment. His track record includes successful product launches at Duma Optronics and significant achievements at Dentaray, where he achieved a 40% reduction in product size through mechanical design optimization.',
//         expertise: [
//           'Solidworks and mechanical design',
//           'Product development from concept to production',
//           'Tolerance analysis and materials expertise',
//           'Opto-mechanical assembly processes',
//           'Cross-cultural team collaboration'
//         ],
//         professionalHistory: [
//           'Senior Mechanical Engineer at Duma Optronics (2022-Present)',
//           'Mechanical Engineer at Dentaray (2018-2022)',
//           'Junior Mechanical Engineer at GOL Linhas Aéreas (2012-2013)'
//         ],
//         education: 'Bachelor\'s Degree in Mechanical Engineering from Centro Universitario Newton Paiva, Brazil',
//         languages: 'Fluent in Portuguese, English, Spanish, and Hebrew',
//         responsibilities: [
//           'Leading mechanical design and product development initiatives',
//           'Overseeing prototype development and testing',
//           'Managing manufacturing processes and quality control',
//           'Coordinating with cross-functional engineering teams',
//           'Driving innovation in mechanical systems and processes'
//         ]
//       }
//     }
//   ];

//   return (
//     <div className="container mx-auto px-6 py-24">
//       <h1 className="text-3xl font-chillax-bold mb-4 text-[#FFFFFF] text-center">Our Team</h1>
//       <p className="text-lg font-avenir text-[#f2f2f2] mb-12 text-center">The visionaries behind Newton's revolutionary VR technology</p>
//       <div className="flex flex-col lg:flex-row gap-8 max-w-7xl mx-auto">
//         {teamMembers.map((member, index) => (
//           <div 
//             key={index} 
//             onClick={() => setSelectedMember(member)}
//             className="relative bg-[#1d2027] p-8 rounded-lg shadow-lg flex flex-col items-center transition-all duration-300 hover:shadow-xl hover:bg-[#2a3540] transform hover:-translate-y-2 hover:scale-105 cursor-pointer group flex-1"
//           >
//             <img 
//               src={member.image} 
//               alt={member.name} 
//               className="w-40 h-40 rounded-full object-cover mb-6"
//             />
//             <div className="text-center">
//               <h2 className="text-2xl font-chillax-bold text-[#FFFFFF] mb-2">{member.name}</h2>
//               <p className="text-lg text-[#E67E22] font-chillax-medium mb-4">{member.role}</p>
//               <p className="text-sm text-[#f2f2f2] font-avenir">{member.bio}</p>
//             </div>
//             <div className="absolute bottom-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
//               <ChevronDown 
//                 size={24} 
//                 color="#E67E22" 
//                 className="animate-bounce cursor-pointer" 
//               />
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Modal Overlay */}
//       {selectedMember && (
//         <div
//           className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50"
//           onClick={() => setSelectedMember(null)}
//         >
//           <div
//             className="bg-[#1d2027] rounded-xl max-w-3xl w-full max-h-[90vh] overflow-y-auto relative [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:bg-[#1d2027] [&::-webkit-scrollbar-track]:rounded-r-xl [&::-webkit-scrollbar-thumb]:bg-[#3a4550] [&::-webkit-scrollbar-thumb]:rounded-full hover:[&::-webkit-scrollbar-thumb]:bg-[#4a5560] [&::-webkit-scrollbar-thumb]:transition-colors"
//             onClick={(e) => e.stopPropagation()}
//           >
//             <button 
//               onClick={(e) => {
//                 e.stopPropagation();
//                 setSelectedMember(null);
//               }}
//               className="sticky top-4 float-right mr-4 bg-[#2a3540] hover:bg-[#3a4550] text-[#f2f2f2] hover:text-white p-2 rounded-full shadow-lg transition-all duration-300 transform hover:scale-110"
//             >
//               <X size={24} />
//             </button>
//             <div className="p-8 clear-right">
              
//               <div className="flex items-start mb-6">
//                 <div className="flex items-center gap-6">
//                   <img 
//                     src={selectedMember.image} 
//                     alt={selectedMember.name}
//                     className="w-32 h-32 rounded-full object-cover"
//                   />
//                   <div>
//                     <h2 className="text-2xl font-chillax-bold text-[#FFFFFF]">{selectedMember.name}</h2>
//                     <p className="text-[#E67E22] font-chillax-medium">{selectedMember.role}</p>
//                   </div>
//                 </div>
//               </div>

//               <div className="space-y-6 text-[#f2f2f2] font-avenir">
//                 <div>
//                   <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Background</h3>
//                   <p>{selectedMember.expandedInfo.background}</p>
//                 </div>

//                 {selectedMember.expandedInfo.expertise && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Areas of Expertise</h3>
//                     <ul className="list-disc pl-6 space-y-2">
//                       {selectedMember.expandedInfo.expertise.map((skill, idx) => (
//                         <li key={idx}>{skill}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 )}

//                 {selectedMember.expandedInfo.professionalHighlights && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Professional Highlights</h3>
//                     <ul className="list-disc pl-6 space-y-2">
//                       {selectedMember.expandedInfo.professionalHighlights.map((highlight, idx) => (
//                         <li key={idx}>{highlight}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 )}

//                 {selectedMember.expandedInfo.professionalHistory && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Professional History</h3>
//                     <ul className="list-disc pl-6 space-y-2">
//                       {selectedMember.expandedInfo.professionalHistory.map((position, idx) => (
//                         <li key={idx}>{position}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 )}

//                 {selectedMember.expandedInfo.education && Array.isArray(selectedMember.expandedInfo.education) ? (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Education</h3>
//                     <ul className="list-disc pl-6 space-y-2">
//                       {selectedMember.expandedInfo.education.map((edu, idx) => (
//                         <li key={idx}>{edu}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 ) : selectedMember.expandedInfo.education && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Education</h3>
//                     <p>{selectedMember.expandedInfo.education}</p>
//                   </div>
//                 )}

//                 {selectedMember.expandedInfo.languages && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Languages</h3>
//                     <p>{selectedMember.expandedInfo.languages}</p>
//                   </div>
//                 )}

//                 <div>
//                   <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Key Responsibilities</h3>
//                   <ul className="list-disc pl-6 space-y-2">
//                     {selectedMember.expandedInfo.responsibilities.map((resp, idx) => (
//                       <li key={idx}>{resp}</li>
//                     ))}
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default Team;



// import React, { useState, useEffect } from 'react';
// import { X, ChevronDown } from 'lucide-react';

// function Team() {
//   const [selectedMember, setSelectedMember] = useState(null);
  
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const teamMembers = [
//     {
//       name: 'David Stahl',
//       role: 'CEO & Founder',
//       image: '/images/david.jpg',
//       bio: 'David holds a dual degree in Economics and Business from the Hebrew University of Jerusalem, specializing in Startup Development and Machine Learning Techniques. As the visionary founder of Newton, he leads the company\'s strategic direction and business growth.',
//       expandedInfo: {
//         background: 'David\'s journey as an innovator began remarkably early - at age 15, he conceived and filed a patent for groundbreaking exoskeleton technology for virtual reality interaction, which was successfully granted in 2022. Originally from Brazil, his entrepreneurial spirit manifested early when he founded Evolve Midias, a music and event production company, while still a teenager. After moving to Israel in 2017, he pursued a dual degree in Economics and Business Administration at the Hebrew University of Jerusalem, combining academic excellence with his natural entrepreneurial drive.',
//         innovations: [
//           'Conceived VR exoskeleton technology at age 15 (2013)',
//           'Successfully obtained patent approval (2022)',
//           'Pioneered new approaches to virtual reality interaction',
//           'Developed innovative business models for VR technology commercialization'
//         ],
//         expertise: [
//           'Machine Learning and Big Data Analytics',
//           'Reinforcement Learning',
//           'Neurolinguistic Programming and Conversational Hypnosis',
//           'Strategic Business Development',
//           'Technology Innovation Management'
//         ],
//         entrepreneurialHistory: [
//           'Founded Newton Technologies (2023-Present)',
//           'Founded Evolve Midias - Music and Event Production Company (2015-2016)',
//           'Filed VR Exoskeleton Patent (2013)',
//           'Patent Approval Secured (2022)'
//         ],
//         education: [
//           'Dual Bachelor\'s Degree in Economics and Business Administration - Hebrew University of Jerusalem (Final Semester)',
//           'Specialization in Startup Development',
//           'Advanced studies in Machine Learning for Big Data and Data Analysis'
//         ],
//         languages: 'Native Portuguese speaker, Full Professional Proficiency in Hebrew and English, Fluent in Spanish',
//         visionAndLeadership: [
//           'Transforming virtual reality interactions through innovative exoskeleton technology',
//           'Leading cross-cultural team development and management',
//           'Bridging technological innovation with market opportunities',
//           'Developing strategic partnerships across industries',
//           'Driving the company\'s vision for future growth and innovation'
//         ],
//         responsibilities: [
//           'Leading overall company strategy and vision',
//           'Managing investor relations and strategic partnerships',
//           'Overseeing product development direction',
//           'Building and leading high-performance teams',
//           'Driving innovation and technological advancement'
//         ]
//       }
//     },
//     {
//       name: 'Bruno Nigri',
//       role: 'CTO',
//       image: '/images/bruno-nigri.jpg',
//       bio: 'Bruno\'s expertise lies in software engineering, particularly in C/C++ and embedded systems. With a background in Computer Software Engineering and Mechatronics, he leads Newton\'s technological advancements and product innovation.',
//       expandedInfo: {
//         background: 'As an experienced Software Engineer, Bruno brings extensive expertise in embedded systems and real-time environments. His dual educational background in Computer Software Engineering from Lev Academic Center and Mechatronics, Robotics, and Automation Engineering from Universidade Presbiteriana Mackenzie provides a unique perspective that combines software excellence with mechanical systems understanding.',
//         expertise: [
//           'Advanced C/C++ programming',
//           'Embedded software development',
//           'Real-time systems and microcontroller programming',
//           'Software architecture and clean coding',
//           'Problem-solving and system optimization'
//         ],
//         professionalHighlights: [
//           'Developed physical cybersecurity solutions in real-time embedded environments at Fibernet Ltd.',
//           'Created three dental laser devices with hard real-time embedded systems at Light Instruments Ltd.',
//           'Achieved 50% speed increase in laser energy calibration through automation',
//           'Implemented microsecond-precise timer systems for laser pulse control'
//         ],
//         education: [
//           'Bachelor of Engineering in Computer Software Engineering - Lev Academic Center, Jerusalem',
//           'Bachelor of Engineering in Mechatronics, Robotics, and Automation - Universidade Presbiteriana Mackenzie, São Paulo'
//         ],
//         languages: 'Native Portuguese speaker, with full professional proficiency in Hebrew and English, and professional working proficiency in Spanish',
//         responsibilities: [
//           'Leading technological strategy and software development',
//           'Overseeing embedded systems architecture and implementation',
//           'Managing real-time system optimization and performance',
//           'Directing software team and development processes',
//           'Ensuring code quality and technical excellence'
//         ]
//       }
//     },
//     {
//       name: 'Lucas Triginelli',
//       role: 'Chief Product Development Officer',
//       image: 'images/lucas.jpg',
//       bio: 'Lucas specializes in mechanical design and product development. With a Bachelor\'s degree in Mechanical Engineering, he oversees all aspects of Newton\'s product design and development processes.',
//       expandedInfo: {
//         background: 'With over 5 years of experience in mechanical engineering, Lucas brings extensive expertise in mechanical design, product development, and testing. Originally from Brazil and now based in Israel for 9 years, his international background enriches our cross-cultural and multidisciplinary engineering environment. His track record includes successful product launches at Duma Optronics and significant achievements at Dentaray, where he achieved a 40% reduction in product size through mechanical design optimization.',
//         expertise: [
//           'Solidworks and mechanical design',
//           'Product development from concept to production',
//           'Tolerance analysis and materials expertise',
//           'Opto-mechanical assembly processes',
//           'Cross-cultural team collaboration'
//         ],
//         professionalHistory: [
//           'Senior Mechanical Engineer at Duma Optronics (2022-Present)',
//           'Mechanical Engineer at Dentaray (2018-2022)',
//           'Junior Mechanical Engineer at GOL Linhas Aéreas (2012-2013)'
//         ],
//         education: 'Bachelor\'s Degree in Mechanical Engineering from Centro Universitario Newton Paiva, Brazil',
//         languages: 'Fluent in Portuguese, English, Spanish, and Hebrew',
//         responsibilities: [
//           'Leading mechanical design and product development initiatives',
//           'Overseeing prototype development and testing',
//           'Managing manufacturing processes and quality control',
//           'Coordinating with cross-functional engineering teams',
//           'Driving innovation in mechanical systems and processes'
//         ]
//       }
//     }
//   ];

//   return (
//     <div className="container mx-auto px-6 py-24">
//       <h1 className="text-3xl font-chillax-bold mb-4 text-[#FFFFFF] text-center">Our Team</h1>
//       <p className="text-lg font-avenir text-[#f2f2f2] mb-12 text-center">The visionaries behind Newton's revolutionary VR technology</p>
//       <div className="flex flex-col lg:flex-row gap-8 max-w-7xl mx-auto">
//         {teamMembers.map((member, index) => (
//           <div 
//             key={index} 
//             onClick={() => setSelectedMember(member)}
//             className="relative bg-[#1d2027] p-8 rounded-lg shadow-lg flex flex-col items-center transition-all duration-300 hover:shadow-xl hover:bg-[#2a3540] transform hover:-translate-y-2 hover:scale-105 cursor-pointer group flex-1"
//           >
//             <img 
//               src={member.image} 
//               alt={member.name} 
//               className="w-40 h-40 rounded-full object-cover mb-6"
//             />
//             <div className="text-center">
//               <h2 className="text-2xl font-chillax-bold text-[#FFFFFF] mb-2">{member.name}</h2>
//               <p className="text-lg text-[#E67E22] font-chillax-medium mb-4">{member.role}</p>
//               {/* Updated bio text size from text-sm to text-base */}
//               <p className="text-base text-[#f2f2f2] font-avenir">{member.bio}</p>
//             </div>
//             <div className="absolute bottom-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
//               <ChevronDown 
//                 size={24} 
//                 color="#E67E22" 
//                 className="animate-bounce cursor-pointer" 
//               />
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Modal Overlay */}
//       {selectedMember && (
//         <div
//           className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50"
//           onClick={() => setSelectedMember(null)}
//         >
//           <div
//             className="bg-[#1d2027] rounded-xl max-w-3xl w-full max-h-[90vh] overflow-y-auto relative [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:bg-[#1d2027] [&::-webkit-scrollbar-track]:rounded-r-xl [&::-webkit-scrollbar-thumb]:bg-[#3a4550] [&::-webkit-scrollbar-thumb]:rounded-full hover:[&::-webkit-scrollbar-thumb]:bg-[#4a5560] [&::-webkit-scrollbar-thumb]:transition-colors"
//             onClick={(e) => e.stopPropagation()}
//           >
//             <button 
//               onClick={(e) => {
//                 e.stopPropagation();
//                 setSelectedMember(null);
//               }}
//               className="sticky top-4 float-right mr-4 bg-[#2a3540] hover:bg-[#3a4550] text-[#f2f2f2] hover:text-white p-2 rounded-full shadow-lg transition-all duration-300 transform hover:scale-110"
//             >
//               <X size={24} />
//             </button>
//             <div className="p-8 clear-right">
              
//               <div className="flex items-start mb-6">
//                 <div className="flex items-center gap-6">
//                   <img 
//                     src={selectedMember.image} 
//                     alt={selectedMember.name}
//                     className="w-32 h-32 rounded-full object-cover"
//                   />
//                   <div>
//                     <h2 className="text-2xl font-chillax-bold text-[#FFFFFF]">{selectedMember.name}</h2>
//                     <p className="text-[#E67E22] font-chillax-medium">{selectedMember.role}</p>
//                   </div>
//                 </div>
//               </div>

//               <div className="space-y-6 text-[#f2f2f2] font-avenir">
//                 <div>
//                   <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Background</h3>
//                   <p>{selectedMember.expandedInfo.background}</p>
//                 </div>

//                 {selectedMember.expandedInfo.expertise && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Areas of Expertise</h3>
//                     <ul className="list-disc pl-6 space-y-2">
//                       {selectedMember.expandedInfo.expertise.map((skill, idx) => (
//                         <li key={idx}>{skill}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 )}

//                 {selectedMember.expandedInfo.professionalHighlights && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Professional Highlights</h3>
//                     <ul className="list-disc pl-6 space-y-2">
//                       {selectedMember.expandedInfo.professionalHighlights.map((highlight, idx) => (
//                         <li key={idx}>{highlight}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 )}

//                 {selectedMember.expandedInfo.professionalHistory && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Professional History</h3>
//                     <ul className="list-disc pl-6 space-y-2">
//                       {selectedMember.expandedInfo.professionalHistory.map((position, idx) => (
//                         <li key={idx}>{position}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 )}

//                 {selectedMember.expandedInfo.education && Array.isArray(selectedMember.expandedInfo.education) ? (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Education</h3>
//                     <ul className="list-disc pl-6 space-y-2">
//                       {selectedMember.expandedInfo.education.map((edu, idx) => (
//                         <li key={idx}>{edu}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 ) : selectedMember.expandedInfo.education && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Education</h3>
//                     <p>{selectedMember.expandedInfo.education}</p>
//                   </div>
//                 )}

//                 {selectedMember.expandedInfo.languages && (
//                   <div>
//                     <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Languages</h3>
//                     <p>{selectedMember.expandedInfo.languages}</p>
//                   </div>
//                 )}

//                 <div>
//                   <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Key Responsibilities</h3>
//                   <ul className="list-disc pl-6 space-y-2">
//                     {selectedMember.expandedInfo.responsibilities.map((resp, idx) => (
//                       <li key={idx}>{resp}</li>
//                     ))}
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default Team;




import React, { useState, useEffect } from 'react';
import { X, ChevronDown } from 'lucide-react';

function Team() {
  const [selectedMember, setSelectedMember] = useState(null);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const teamMembers = [
    {
      name: 'David Stahl',
      role: 'CEO & Founder',
      image: '/images/david.jpg',
      bio: 'David holds a dual degree in Economics and Business from the Hebrew University of Jerusalem, specializing in Startup Development and Machine Learning Techniques. As the visionary founder of Newton, he leads the company\'s strategic direction and business growth.',
      expandedInfo: {
        background: 'David\'s journey as an innovator began remarkably early - at age 15, he conceived and filed a patent for groundbreaking exoskeleton technology for virtual reality interaction, which was successfully granted in 2022. Originally from Brazil, his entrepreneurial spirit manifested early when he founded Evolve Midias, a music and event production company, while still a teenager. After moving to Israel in 2017, he pursued a dual degree in Economics and Business Administration at the Hebrew University of Jerusalem, combining academic excellence with his natural entrepreneurial drive.',
        innovations: [
          'Conceived VR exoskeleton technology at age 15 (2013)',
          'Successfully obtained patent approval (2022)',
          'Pioneered new approaches to virtual reality interaction',
          'Developed innovative business models for VR technology commercialization'
        ],
        expertise: [
          'Machine Learning and Big Data Analytics',
          'Neurolinguistic Programming and Conversational Hypnosis',
          'Strategic Business Development',
          'Technology Innovation Management'
        ],
        entrepreneurialHistory: [
          'Founded Newton Technologies (2023-Present)',
          'Founded Evolve Midias - Music and Event Production Company (2015-2016)',
          'Filed VR Exoskeleton Patent (2013)',
          'Patent Approval Secured (2022)'
        ],
        education: [
          'Dual Bachelor\'s Degree in Economics and Business Administration - Hebrew University of Jerusalem (Final Semester)',
          'Specialization in Startup Development',
          'Advanced studies in Machine Learning for Big Data and Data Analysis'
        ],
        languages: [
          { language: 'Portuguese', proficiency: 'Native or Bilingual Proficiency' },
          { language: 'English', proficiency: 'Full Professional Proficiency' },
          { language: 'Spanish', proficiency: 'Fluent' },
          { language: 'Hebrew', proficiency: 'Full Professional Proficiency' }
        ],
        visionAndLeadership: [
          'Transforming virtual reality interactions through innovative exoskeleton technology',
          'Leading cross-cultural team development and management',
          'Bridging technological innovation with market opportunities',
          'Developing strategic partnerships across industries',
          'Driving the company\'s vision for future growth and innovation'
        ],
        responsibilities: [
          'Leading overall company strategy and vision',
          'Managing investor relations and strategic partnerships',
          'Overseeing product development direction',
          'Building and leading high-performance teams',
          'Driving innovation and technological advancement'
        ]
      }
    },
    {
      name: 'Bruno Nigri',
      role: 'CTO',
      image: '/images/bruno-nigri.jpg',
      bio: 'Bruno\'s expertise lies in software engineering, particularly in C/C++ and embedded systems. With a background in Computer Software Engineering and Mechatronics, he leads Newton\'s technological advancements and product innovation.',
      expandedInfo: {
        background: 'As an experienced Software Engineer, Bruno brings extensive expertise in embedded systems and real-time environments. His dual educational background in Computer Software Engineering from Lev Academic Center and Mechatronics, Robotics, and Automation Engineering from Universidade Presbiteriana Mackenzie provides a unique perspective that combines software excellence with mechanical systems understanding.',
        expertise: [
          'Advanced C/C++ programming',
          'Embedded software development',
          'Real-time systems and microcontroller programming',
          'Software architecture and clean coding',
          'Problem-solving and system optimization'
        ],
        professionalHighlights: [
          'Developed physical cybersecurity solutions in real-time embedded environments at Fibernet Ltd.',
          'Created three dental laser devices with hard real-time embedded systems at Light Instruments Ltd.',
          'Achieved 50% speed increase in laser energy calibration through automation',
          'Implemented microsecond-precise timer systems for laser pulse control'
        ],
        education: [
          'Bachelor of Engineering in Computer Software Engineering - Lev Academic Center, Jerusalem',
          'Bachelor of Engineering in Mechatronics, Robotics, and Automation - Universidade Presbiteriana Mackenzie, São Paulo'
        ],
        languages: [
          { language: 'Portuguese', proficiency: 'Native or Bilingual Proficiency' },
          { language: 'English', proficiency: 'Full Professional Proficiency' },
          { language: 'Spanish', proficiency: 'Professional Working Proficiency' },
          { language: 'Hebrew', proficiency: 'Full Professional Proficiency' }
        ],
        responsibilities: [
          'Leading technological strategy and software development',
          'Overseeing embedded systems architecture and implementation',
          'Managing real-time system optimization and performance',
          'Directing software team and development processes',
          'Ensuring code quality and technical excellence'
        ]
      }
    },
    {
      name: 'Lucas Triginelli',
      role: 'Chief Product Development Officer',
      image: '/images/lucas.jpg',
      bio: 'Lucas specializes in mechanical design and product development. With a Bachelor\'s degree in Mechanical Engineering, he oversees all aspects of Newton\'s product design and development processes.',
      expandedInfo: {
        background: 'With over 5 years of experience in mechanical engineering, Lucas brings extensive expertise in mechanical design, product development, and testing. Originally from Brazil and now based in Israel for 9 years, his international background enriches our cross-cultural and multidisciplinary engineering environment. His track record includes successful product launches at Duma Optronics and significant achievements at Dentaray, where he achieved a 40% reduction in product size through mechanical design optimization.',
        expertise: [
          'Solidworks and mechanical design',
          'Product development from concept to production',
          'Tolerance analysis and materials expertise',
          'Opto-mechanical assembly processes',
          'Cross-cultural team collaboration'
        ],
        professionalHistory: [
          'Senior Mechanical Engineer at Duma Optronics (2022-Present)',
          'Mechanical Engineer at Dentaray (2018-2022)',
          'Junior Mechanical Engineer at GOL Linhas Aéreas (2012-2013)'
        ],
        education: 'Bachelor\'s Degree in Mechanical Engineering from Centro Universitario Newton Paiva, Brazil',
        languages: [
          { language: 'Portuguese', proficiency: 'Native or Bilingual Proficiency' },
          { language: 'English', proficiency: 'Full Professional Proficiency' },
          { language: 'Spanish', proficiency: 'Full Professional Proficiency' },
          { language: 'Hebrew', proficiency: 'Full Professional Proficiency' }
        ],
        responsibilities: [
          'Leading mechanical design and product development initiatives',
          'Overseeing prototype development and testing',
          'Managing manufacturing processes and quality control',
          'Coordinating with cross-functional engineering teams',
          'Driving innovation in mechanical systems and processes'
        ]
      }
    }
  ];

  return (
    <div className="container mx-auto px-6 py-24">
      <h1 className="text-3xl font-chillax-bold mb-4 text-[#FFFFFF] text-center">Our Team</h1>
      <p className="text-lg font-avenir text-[#f2f2f2] mb-12 text-center">The visionaries behind Newton's revolutionary VR technology</p>
      <div className="flex flex-col lg:flex-row gap-8 max-w-7xl mx-auto">
        {teamMembers.map((member, index) => (
          <div 
            key={index} 
            onClick={() => setSelectedMember(member)}
            className="relative bg-[#1d2027] p-8 rounded-lg shadow-lg flex flex-col items-center transition-all duration-300 hover:shadow-xl hover:bg-[#2a3540] transform hover:-translate-y-2 hover:scale-105 cursor-pointer group flex-1"
          >
            <img 
              src={member.image} 
              alt={member.name} 
              className="w-40 h-40 rounded-full object-cover mb-6"
            />
            <div className="text-center">
              <h2 className="text-2xl font-chillax-bold text-[#FFFFFF] mb-2">{member.name}</h2>
              <p className="text-lg text-[#E67E22] font-chillax-medium mb-4">{member.role}</p>
              {/* Updated bio text size from text-sm to text-base */}
              <p className="text-base text-[#f2f2f2] font-avenir">{member.bio}</p>
            </div>
            <div className="absolute bottom-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <ChevronDown 
                size={24} 
                color="#E67E22" 
                className="animate-bounce cursor-pointer" 
              />
            </div>
          </div>
        ))}
      </div>

      {/* Modal Overlay */}
      {selectedMember && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50"
          onClick={() => setSelectedMember(null)}
        >
          <div
            className="bg-[#1d2027] rounded-xl max-w-3xl w-full max-h-[90vh] overflow-y-auto relative [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:bg-[#1d2027] [&::-webkit-scrollbar-track]:rounded-r-xl [&::-webkit-scrollbar-thumb]:bg-[#3a4550] [&::-webkit-scrollbar-thumb]:rounded-full hover:[&::-webkit-scrollbar-thumb]:bg-[#4a5560] [&::-webkit-scrollbar-thumb]:transition-colors"
            onClick={(e) => e.stopPropagation()}
          >
            <button 
              onClick={(e) => {
                e.stopPropagation();
                setSelectedMember(null);
              }}
              className="sticky top-4 float-right mr-4 bg-[#2a3540] hover:bg-[#3a4550] text-[#f2f2f2] hover:text-white p-2 rounded-full shadow-lg transition-all duration-300 transform hover:scale-110"
            >
              <X size={24} />
            </button>
            <div className="p-8 clear-right">
              
              <div className="flex items-start mb-6">
                <div className="flex items-center gap-6">
                  <img 
                    src={selectedMember.image} 
                    alt={selectedMember.name}
                    className="w-32 h-32 rounded-full object-cover"
                  />
                  <div>
                    <h2 className="text-2xl font-chillax-bold text-[#FFFFFF]">{selectedMember.name}</h2>
                    <p className="text-[#E67E22] font-chillax-medium">{selectedMember.role}</p>
                  </div>
                </div>
              </div>

              <div className="space-y-6 text-[#f2f2f2] font-avenir">
                <div>
                  <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Background</h3>
                  <p>{selectedMember.expandedInfo.background}</p>
                </div>

                {selectedMember.expandedInfo.expertise && (
                  <div>
                    <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Areas of Expertise</h3>
                    <ul className="list-disc pl-6 space-y-2">
                      {selectedMember.expandedInfo.expertise.map((skill, idx) => (
                        <li key={idx}>{skill}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {selectedMember.expandedInfo.professionalHighlights && (
                  <div>
                    <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Professional Highlights</h3>
                    <ul className="list-disc pl-6 space-y-2">
                      {selectedMember.expandedInfo.professionalHighlights.map((highlight, idx) => (
                        <li key={idx}>{highlight}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {selectedMember.expandedInfo.professionalHistory && (
                  <div>
                    <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Professional History</h3>
                    <ul className="list-disc pl-6 space-y-2">
                      {selectedMember.expandedInfo.professionalHistory.map((position, idx) => (
                        <li key={idx}>{position}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {selectedMember.expandedInfo.education && Array.isArray(selectedMember.expandedInfo.education) ? (
                  <div>
                    <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Education</h3>
                    <ul className="list-disc pl-6 space-y-2">
                      {selectedMember.expandedInfo.education.map((edu, idx) => (
                        <li key={idx}>{edu}</li>
                      ))}
                    </ul>
                  </div>
                ) : selectedMember.expandedInfo.education && (
                  <div>
                    <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Education</h3>
                    <p>{selectedMember.expandedInfo.education}</p>
                  </div>
                )}

                {selectedMember.expandedInfo.languages && (
                  <div>
                    <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Languages</h3>
                    <ul className="list-disc pl-6 space-y-2">
                      {selectedMember.expandedInfo.languages.map((lang, idx) => (
                        <li key={idx}>
                          <strong>{lang.language}:</strong> {lang.proficiency}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                <div>
                  <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Key Responsibilities</h3>
                  <ul className="list-disc pl-6 space-y-2">
                    {selectedMember.expandedInfo.responsibilities.map((resp, idx) => (
                      <li key={idx}>{resp}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Team;
