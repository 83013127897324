import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Zap, Globe, Users, Briefcase, ChevronRight, Brain, Rocket, Target, Heart, Star } from 'lucide-react';
import { motion } from 'framer-motion';

function Vision() {
  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <div className="bg-gradient-to-b from-[#1d2027] to-[#2a3540] min-h-screen text-white">
      <div className="container mx-auto px-4 sm:px-6 py-24 max-w-7xl">
        <motion.h1 
          className="text-5xl sm:text-6xl md:text-7xl font-chillax-bold mb-8 text-[#E67E22] leading-tight text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Pioneering the Future of VR Interaction
        </motion.h1>
        <motion.p 
          className="text-xl sm:text-2xl font-avenir text-[#f2f2f2] mb-16 max-w-3xl mx-auto leading-relaxed text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          At Newton, we're erasing the boundaries between physical and digital realms, empowering you to physically interact with virtual worlds.
        </motion.p>

        {/* Vision and Mission Section */}
        <section className="mb-32">
          <div className="grid md:grid-cols-2 gap-12 lg:gap-16">
            <motion.div
              className="bg-[#2a3540] p-8 rounded-2xl shadow-lg border-l-4 border-[#E67E22]"
              variants={fadeIn}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
            >
              <h2 className="text-3xl font-chillax-bold mb-6 text-[#E67E22]">Our Vision</h2>
              <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed">
                We aspire to lead the fusion of digital and physical realms, making our exoskeleton technology synonymous with advanced human-machine interaction. Within a decade, we envision Newton as a global leader fostering innovation, education, and human development through physical engagement with virtual worlds.
              </p>
            </motion.div>
            <motion.div
              className="bg-[#2a3540] p-8 rounded-2xl shadow-lg border-l-4 border-[#E67E22]"
              variants={fadeIn}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
            >
              <h2 className="text-3xl font-chillax-bold mb-6 text-[#E67E22]">Our Mission</h2>
              <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed">
                Newton's mission is to empower individuals and organizations by transcending physical limitations. Our innovative exoskeleton provides unprecedented immersion in virtual reality, revolutionizing entertainment, education, and professional training through continuous innovation and strategic collaborations.
              </p>
            </motion.div>
          </div>
        </section>

        {/* Core Values Section */}
        <section className="mb-32">
          <motion.h2 
            className="text-4xl font-chillax-bold text-white mb-12 text-center"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            Our Core Values
          </motion.h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
            {[
              { icon: Brain, title: "Futuristic Thinking", description: "Pioneering tomorrow's technology today." },
              { icon: Users, title: "Agile Collaboration", description: "Fostering innovation through teamwork." },
              { icon: Rocket, title: "Autonomy with Responsibility", description: "Empowering our team to drive innovation." },
              { icon: Target, title: "Growth Mindset", description: "Continuously learning and adapting." },
              { icon: Heart, title: "Customer Orientation", description: "Putting user needs at the forefront." },
              { icon: Star, title: "Passion for Excellence", description: "Striving for the highest quality in all we do." },
            ].map((value, index) => (
              <motion.div
                key={index}
                className="bg-[#2a3540] p-6 rounded-xl shadow-lg text-center"
                variants={fadeIn}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
              >
                <value.icon size={40} className="text-[#E67E22] mb-4 mx-auto" />
                <h3 className="text-xl font-chillax-bold mb-2 text-white">{value.title}</h3>
                <p className="text-sm text-[#f2f2f2] font-avenir">{value.description}</p>
              </motion.div>
            ))}
          </div>
        </section>

        {/* Horizontal Separator */}
        <div className="w-full h-px bg-gradient-to-r from-transparent via-[#E67E22] to-transparent my-24"></div>

        {/* Future Impact Section */}
        <section className="mb-32">
          <motion.h2 
            className="text-4xl font-chillax-bold text-white mb-12 text-center"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            Shaping the Future of Immersive Experiences
          </motion.h2>

          <div className="grid md:grid-cols-2 gap-10 mb-16">
            {[
              {
                icon: <Zap size={32} className="text-[#E67E22]" />,
                title: "Advanced Professional Training",
                description: "Conduct high-stakes training in hyper-realistic virtual environments, enhancing skills without real-world risks."
              },
              {
                icon: <Globe size={32} className="text-[#E67E22]" />,
                title: "Revolutionary Product Development",
                description: "Enable engineers and designers to physically interact with virtual prototypes, accelerating innovation cycles."
              },
              {
                icon: <Users size={32} className="text-[#E67E22]" />,
                title: "Immersive Educational Experiences",
                description: "Create fully immersive, interactive VR experiences for more engaging and effective learning."
              },
              {
                icon: <Briefcase size={32} className="text-[#E67E22]" />,
                title: "Next-Generation Entertainment",
                description: "Blur the line between reality and virtual worlds, offering unprecedented levels of immersion and excitement."
              }
            ].map((impact, index) => (
              <motion.div
                key={index}
                className="bg-[#2a3540] p-8 rounded-2xl shadow-lg"
                variants={fadeIn}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
              >
                <div className="flex items-center mb-4">
                  {impact.icon}
                  <h3 className="text-2xl font-chillax-bold ml-4 text-white">{impact.title}</h3>
                </div>
                <p className="text-md text-[#f2f2f2] font-avenir leading-relaxed">{impact.description}</p>
              </motion.div>
            ))}
          </div>
        
          {/* Learn More Button */}
          <div className="text-center">
            <Link to="/exoskeleton-applications">
              <motion.button
                className="bg-[#E67E22] text-white px-8 py-4 rounded-full transition duration-300 flex items-center text-lg font-semibold mx-auto shadow-lg hover:shadow-xl"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Explore Exoskeleton Applications <ChevronRight className="ml-2" size={20} />
              </motion.button>
            </Link>
          </div>
        </section>

        <motion.div 
          className="mt-24 p-10 bg-[#2a3540] rounded-2xl shadow-xl"
          variants={fadeIn}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <h3 className="text-3xl font-chillax-bold mb-8 text-white">Redefining Human-Machine Interaction</h3>
          <div className="space-y-6 text-lg text-[#f2f2f2] font-avenir leading-relaxed">
            <p>
              At Newton, we're crafting the future of human interaction with digital worlds. Our exoskeleton technology, coupled with advanced AI and haptic feedback systems, is set to redefine the boundaries of virtual and augmented reality.
            </p>
            <p>
              We envision a future where our technology enhances human capabilities across all sectors, creating a world where digital and physical realms seamlessly intertwine.
            </p>
            <p>
              Join us in our journey to redefine how humans interact with digital environments, and be part of the next great leap in immersive technology.
            </p>
          </div>
          <Link to="/contact-us">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="mt-10 bg-[#E67E22] text-white py-4 px-8 rounded-full text-lg font-chillax-bold shadow-lg hover:shadow-xl"
            >
              Be Part of Our Vision
            </motion.button>
          </Link>
        </motion.div>
      </div>
    </div>
  );
}

export default Vision;