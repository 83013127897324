import React from 'react';
import Header from './Header';
import Footer from './Footer';

function Layout({ children }) {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1d2027] via-[#2a3540] to-[#3c4e5c] text-[#f2f2f2] font-avenir">
      <Header />
      <main className="overflow-hidden">
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default Layout;