import React, { useEffect, useState } from 'react';
import { Send, Phone, Mail, MapPin } from 'lucide-react';
import { motion } from 'framer-motion';
import { useForm } from "react-hook-form";
import useWeb3Forms from "@web3forms/react";

export default function ContactUs() {
  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [result, setResult] = useState("");
  const { register, handleSubmit, reset } = useForm();

  const accessKey = process.env.REACT_APP_WEB3FORMS_ACCESS_KEY;

  const { submit: onSubmit } = useWeb3Forms({
    access_key: accessKey,
    settings: {
      from_name: "Newton XR Contact Form",
      subject: "New Contact Message from Newton XR Website",
    },
    onSuccess: (msg, data) => {
      setResult("Form Submitted Successfully");
      reset();
    },
    onError: (msg, data) => {
      setResult("Failed to submit form. Please try again later.");
    },
  });

  return (
    <div className="container mx-auto px-6 py-24 max-w-7xl">
      <h1 className="text-5xl font-chillax-bold mb-6 text-white leading-tight">Contact Us</h1>
      <p className="text-xl font-avenir text-[#f2f2f2] mb-12 max-w-3xl">
        Have questions about ExoSuit VR or want to explore how our technology can benefit your industry? We're here to help.
      </p>

      <div className="grid md:grid-cols-2 gap-12">
        {/* Contact Form */}
        <div>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <input type="hidden" value={accessKey} {...register("access_key")} />
            <input type="hidden" value="https://web3forms.com/success" {...register("redirect")} />

            <div>
              <label htmlFor="name" className="block text-[#f2f2f2] font-avenir mb-2">Name</label>
              <input
                type="text"
                id="name"
                {...register("name", { required: true })}
                className="w-full px-4 py-2 rounded-md bg-[#2a3540] text-[#f2f2f2] focus:outline-none focus:ring-2 focus:ring-[#E67E22]"
                placeholder="Your name"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-[#f2f2f2] font-avenir mb-2">Email</label>
              <input
                type="email"
                id="email"
                {...register("email", { required: true })}
                className="w-full px-4 py-2 rounded-md bg-[#2a3540] text-[#f2f2f2] focus:outline-none focus:ring-2 focus:ring-[#E67E22]"
                placeholder="your@email.com"
              />
            </div>
            <div>
              <label htmlFor="subject" className="block text-[#f2f2f2] font-avenir mb-2">Subject</label>
              <input
                type="text"
                id="subject"
                {...register("subject", { required: true })}
                className="w-full px-4 py-2 rounded-md bg-[#2a3540] text-[#f2f2f2] focus:outline-none focus:ring-2 focus:ring-[#E67E22]"
                placeholder="Your subject"
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-[#f2f2f2] font-avenir mb-2">Message</label>
              <textarea
                id="message"
                {...register("message", { required: true })}
                rows="4"
                className="w-full px-4 py-2 rounded-md bg-[#2a3540] text-[#f2f2f2] focus:outline-none focus:ring-2 focus:ring-[#E67E22]"
                placeholder="Your message"
              ></textarea>
            </div>
            <motion.button
              type="submit"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-[#E67E22] text-white px-6 py-3 rounded-md font-chillax-bold flex items-center"
            >
              Send Message <Send className="ml-2" size={20} />
            </motion.button>
          </form>
          <span className="mt-4 text-[#f2f2f2] font-avenir">{result}</span>
        </div>

        {/* Contact Information */}
        <div className="space-y-8">
          <div>
            <h2 className="text-2xl font-chillax-bold text-[#E67E22] mb-4">Contact Information</h2>
            <p className="text-[#f2f2f2] font-avenir mb-2 flex items-center">
              <Mail className="mr-2" size={20} /> info@newtonxr.com
            </p>
            <p className="text-[#f2f2f2] font-avenir mb-2 flex items-center">
              <Phone className="mr-2" size={20} /> +972 559572906
            </p>
            <p className="text-[#f2f2f2] font-avenir flex items-center">
              <MapPin className="mr-2" size={20} /> Tel Aviv
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-chillax-bold text-[#E67E22] mb-4">Business Hours</h2>
            <p className="text-[#f2f2f2] font-avenir">Sunday - Thursday: 9:00 AM - 6:00 PM</p>
            <p className="text-[#f2f2f2] font-avenir">Friday - Saturday: Closed</p>
          </div>
          <div className="bg-gradient-to-br from-[#2a3540] to-[#1d2027] p-6 rounded-xl">
            <h2 className="text-2xl font-chillax-bold text-[#E67E22] mb-4">Get in Touch</h2>
            <p className="text-[#f2f2f2] font-avenir">
              Whether you're looking to revolutionize your industry with ExoSuit VR or simply want to learn more about our technology, we're here to assist you. Don't hesitate to reach out!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}